import * as React from "react";
import Box from "@mui/material/Box";
import Collapse from "@mui/material/Collapse";
import IconButton from "@mui/material/IconButton";
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell from "@mui/material/TableCell";
import TableContainer from "@mui/material/TableContainer";
import TableHead from "@mui/material/TableHead";
import TableRow from "@mui/material/TableRow";
import Paper from "@mui/material/Paper";
import KeyboardArrowDownIcon from "@mui/icons-material/KeyboardArrowDown";
import KeyboardArrowUpIcon from "@mui/icons-material/KeyboardArrowUp";
import {
  Alert,
  AlertTitle,
  Button,
  Grid,
  Stack,
  TextField,
  Typography,
} from "@mui/material";
import { ArrowBackOutlined, Cancel, Done } from "@mui/icons-material";
import { useNavigate, useParams } from "react-router-dom";
import { CaricoMerceContext } from "../contexts";
import HourglassBottomIcon from "@mui/icons-material/HourglassBottom";
import DoNotDisturbIcon from "@mui/icons-material/DoNotDisturb";
import {  ModalsView } from "../components";
import { DataGrid } from "@mui/x-data-grid";
import { useForm } from "../hooks";
import ReactMarkdown from "react-markdown";
import rehypeRaw from "rehype-raw";

function Row(props) {
  const { row, idViaggio, openModal, notaAutista, navigate} = props;
  const [open, setOpen] = React.useState(false);

  

  React.useEffect(() => {
    if (row.TestoTracking) {
      setValues({ notaViaggio: row.TestoTracking });
    }
  }, []);

  const {
    values: formNotaViaggio,
    changeHandler,
    setValues,
  } = useForm({ noteViaggio: "...Caricamento" });

  const { 
    updateSpedizione, 
    getInfoColli 
  } = React.useContext(CaricoMerceContext);

  const updateCaricoMerce = (action) => {
    let body = {
      codiceevento: action,
      note: formNotaViaggio.notaViaggio,
      NotaViaggio_Autista: notaAutista.notaAutista,
    };
    updateSpedizione(idViaggio, row.PrgInterno, body, setOpen);
  };

  const updateCambioViaggio = (action) => {
    navigate(`/carico-merce-in-uscita/cambio-viaggio/${idViaggio}/${row.PrgInterno}`);
    
  };

  const IconCaricoMerce = () => {
    switch (row.StatoDiCarico) {
      case "CAR": {
        return (
          <IconButton
            color={"success"}
            aria-label="upload picture"
            component="span"
          >
            <Done />
          </IconButton>
        );
      }
      case "NONCAR": {
        return (
          <IconButton
            color={"error"}
            aria-label="upload picture"
            component="span"
          >
            <DoNotDisturbIcon />
          </IconButton>
        );
      }
      case "PARZ": {
        return (
          <IconButton
            color={"warning"}
            aria-label="upload picture"
            component="span"
          >
            <HourglassBottomIcon />
          </IconButton>
        );
      }
      default: {
        return (
          <IconButton
            color={"default"}
            aria-label="upload picture"
            component="span"
          >
            <Cancel />
          </IconButton>
        );
      }
    }
  };

  const openInfoColli = () => {
    openModal(true);
    getInfoColli(row.PrgInterno);
  };

  return (
    <>
      <TableRow sx={{ "& > *": { border: 0 } }}>
        <TableCell>
          <IconButton
            aria-label="expand row"
            size="small"
            onClick={() => setOpen(!open)}
          >
            {open ? <KeyboardArrowUpIcon /> : <KeyboardArrowDownIcon />}
          </IconButton>
        </TableCell>
        <TableCell component="th" scope="row">
          {row.MittRagSoc}
        </TableCell>
        <TableCell component="th" scope="row">
          {row.MittLocalita}
        </TableCell>
        <TableCell component="th" scope="row" sx={{ fontWeight: "bold" }}>
          {row.DestRagSoc}
        </TableCell>
        <TableCell component="th" scope="row" sx={{ fontWeight: "bold" }}>
          {row.DestLocalita}
        </TableCell>
        <TableCell component="th" scope="row" onClick={() => openInfoColli()}>
          {row.Colli}
        </TableCell>
        <TableCell component="th" scope="row">
          {row.Peso}
        </TableCell>
        <TableCell component="th" scope="row">
          <IconCaricoMerce />
        </TableCell>
      </TableRow>
      <TableRow>
        <TableCell style={{ paddingBottom: 0, paddingTop: 0 }} colSpan={6}>
          <Collapse in={open} timeout="auto" unmountOnExit>
            <Box sx={{ marginBottom: 4 }}>
              <Grid container spacing={2} direction={"column"} mt={2}>
                <Grid item xs={12} md={12} lg={12}>
                  <Typography>
                    {row.NoteMagazzino}
                  </Typography>
                </Grid>
                <Grid item xs={12} md={12} lg={12}>
                  <TextField
                    hiddenLabel
                    id="filled-hidden-label-normal"
                    variant="filled"
                    placeholder="note"
                    autoComplete="off"
                    sx={{ width: "90%" }}
                    value={formNotaViaggio.notaViaggio || ""}
                    onChange={(e) =>
                      changeHandler("notaViaggio", e.target.value)
                    }
                  />
                </Grid>
                <Grid item xs={12} md={12} lg={12}>
                  <Stack
                    spacing={2}
                    direction={
                      "row"
                    } /* justifyContent={"center"} alignItems={"center"} */
                  >
                    <Button
                      color="success"
                      variant="contained"
                      onClick={() => updateCaricoMerce("CAR")}
                    >
                      Caricato
                    </Button>
                    <Button
                      color="inherit"
                      variant="contained"
                      onClick={() => updateCaricoMerce("PARZ")}
                    >
                      Carico parziale
                    </Button>
                    <Button
                      color="error"
                      variant="contained"
                      onClick={() => updateCaricoMerce("NONCAR")}
                    >
                      Non caricato
                    </Button>

                    <Button
                      color="warning"
                      variant="contained"
                      onClick={() => updateCambioViaggio("CAMVIA")}
                    >
                      Sposta su altro viaggio
                    </Button>
                  </Stack>
                </Grid>
              </Grid>
            </Box>
          </Collapse>
        </TableCell>
      </TableRow>
    </>
  );
}

export default function MagCaricoUscitaViaggio() {
  const [openModal, setOpenModal] = React.useState(false);
  const [cambioDialogOpen, setCambioDialogOpen] = React.useState(false);

  const { innerWidth: width } = window;
  let idCarico = useParams();
  let navigate = useNavigate();

  const {
    state: { viaggio, infoViaggio, infoColli },
    getViaggio,
  } = React.useContext(CaricoMerceContext);


  const {
    values: formNota,
    changeHandler,
    setValues,
  } = useForm({ notaAutista: "" });

  React.useEffect(() => {
    if (idCarico) {
      getViaggio(idCarico.idviaggio);
    }
  }, []);

  React.useEffect(() => {
    if (infoViaggio) {
      setValues({ notaAutista: infoViaggio.NotaViaggio_Autista });
    }
  }, [infoViaggio]);

  const columns = [
    {
      field: "DescrizioneMerce",
      headerName: "Descrizione",
      flex: 1,
    },
    {
      field: "Colli",
      headerName: "Colli",
    },
    {
      field: "Peso",
      headerName: "Peso",
    },
  ];

  function CustomFooterStatusComponent() {
    return <div style={{ width: width / 2 }}></div>;
  }

  return (
    <Grid container>
      <Grid item xs={12} md={12} lg={12} ml={1} mr={1}>
        <Button
          startIcon={<ArrowBackOutlined />}
          onClick={() => navigate("/carico-merce-in-uscita")}
          color="info"
        >
          torna indietro
        </Button>
        <Alert severity="info" component="span">
          <AlertTitle>
            {" "}
            <strong>
              {
                <ReactMarkdown
                  children={
                    infoViaggio?.displaytext
                      ? infoViaggio.displaytext.toString()
                      : " "
                  }
                  rehypePlugins={[rehypeRaw]}
                ></ReactMarkdown>
              }{" "}
            </strong>
          </AlertTitle>
        </Alert>
        <TextField
          hiddenLabel
          fullWidth
          id="filled-hidden-label-normal"
          variant="filled"
          placeholder="note"
          autoComplete="off"
          value={formNota.notaAutista || ""}
          onChange={(e) => changeHandler("notaAutista", e.target.value)}
        />
        <Alert severity="success" component="span">
          <AlertTitle>
            {" "}
            <strong>
              {
                <ReactMarkdown
                  children={infoViaggio ? infoViaggio.summarytext : ""}
                  rehypePlugins={[rehypeRaw]}
                ></ReactMarkdown>
              }{" "}
            </strong>
          </AlertTitle>
        </Alert>
      </Grid>
      <Grid item xs={12} md={12} lg={12} mt={2}>
        <Stack justifyContent={"center"} alignItems={"center"}>
          <TableContainer component={Paper}>
            <Table aria-label="collapsible table">
              <TableHead>
                <TableRow>
                  <TableCell />
                  <TableCell>Mittente</TableCell>
                  <TableCell>Località</TableCell>
                  <TableCell sx={{ fontWeight: "bold" }}>
                    Destinatario{" "}
                  </TableCell>
                  <TableCell>Indirizzo</TableCell>
                  <TableCell>Colli</TableCell>
                  <TableCell>Peso</TableCell>
                  <TableCell>Stato</TableCell>
                </TableRow>
              </TableHead>
              <TableBody>
                {viaggio.map((row) => (
                  <Row
                    key={row.NPratica + "" + row.PrgInterno}
                    row={row}
                    idViaggio={idCarico.idviaggio}
                    openModal={setOpenModal}
                    notaAutista={formNota}
                    cambioDialogOpen={cambioDialogOpen}
                    setCambioDialogOpen={setCambioDialogOpen}
                    navigate={navigate}
                  />
                ))}
              </TableBody>
            </Table>
          </TableContainer>
          <ModalsView
            openModal={openModal}
            closeModal={() => setOpenModal(false)}
            component={
              <DataGrid
                rows={infoColli}
                columns={columns}
                getRowId={(row) => row.Colli * row.Peso}
                autoHeight
                fullWidth
                components={{
                  Footer: CustomFooterStatusComponent,
                }}
              />
            }
          />
        </Stack>
      </Grid>

    </Grid>
  );
}
