import createDataContext from "./createContext";
import { SpedizioniService } from "../api";
import { useUrlClient } from "../hooks";
import { sendNotification } from "../hooks/useNotification";
import { ErrorTitle } from "../components";

const LISTA_INVENTARIO = "lista_inventario";
const CUSTOM_COLUMN = "custom_column";
const CAMPI_CHIAVE = "campi_chiave";

const LISTA_INVENTARIO_HUB = "lista_inventario_hub";
const CUSTOM_COLUMN_HUB = "custom_column_hub";
const CAMPI_CHIAVE_HUB = "campi_chiave_hub";
const LISTA_HUB_ALL = "lista_hub_all";
const CUSTOM_COLUMN_HUB_ALL = "custom_column_hub_all";

const INITIAL_STATE = {
  listRecord: [],
  customColumn: [],
  displayText: "",
  campiChiave: [],
  listRecordHub: [],
  customColumnHub: [],
  displayTextHub: "",
  campiChiaveHub: [],
  listaHubAll: [],
  customColumnHubAll: [],
};

const posizioneMerceReducer = (state, action) => {
  switch (action.type) {
    case LISTA_INVENTARIO: {
      return { ...state, listRecord: action.payload };
    }
    case CUSTOM_COLUMN: {
      return { ...state, customColumn: action.payload };
    }
    case CAMPI_CHIAVE: {
      const { campi, display } = action.payload;
      return { ...state, campiChiave: campi, displayText: display };
    }
    case LISTA_INVENTARIO_HUB: {
      return { ...state, listRecordHub: action.payload };
    }
    case CUSTOM_COLUMN_HUB: {
      return { ...state, customColumnHub: action.payload };
    }
    case CAMPI_CHIAVE_HUB: {
      const { campi, display } = action.payload;
      return { ...state, campiChiaveHub: campi, displayTextHub: display };
    }
    case LISTA_HUB_ALL: {
      return { ...state, listaHubAll: action.payload };
    }
    case CUSTOM_COLUMN_HUB_ALL: {
      return { ...state, customColumnHubAll: action.payload };
    }
    default: {
      return state;
    }
  }
};

const getListaInventario = (dispatch) => async (code, navigate) => {
  const response = await SpedizioniService.get(
    `${(await useUrlClient()).baseUrl}/magmovim/inventario/barcode/${code}`
  );
  if (response.data) {
    navigate(
      `/inventario-magazzino/${response.data.campichiave.PrgInventario}`
    );
  }
};

const getInventarioDetail = (dispatch) => async (code, navigate) => {
  const response = await SpedizioniService.get(
    `${(await useUrlClient()).baseUrl}/magmovim/inventario/${code}`
  );
  if (response.data) {
    dispatch({ type: LISTA_INVENTARIO, payload: response.data.magmerce });
    dispatch({
      type: CAMPI_CHIAVE,
      payload: {
        campi: response.data.campichiave,
        display: response.data.displaytext,
      },
    });
    dispatch({ type: CUSTOM_COLUMN, payload: response.data.columns });
  }
};

const postRecordInventario = (dispatch) => async (data, code) => {
  const response = await SpedizioniService.post(
    `${(await useUrlClient()).baseUrl}/magmovim/inventario/record`,
    data
  );
  if (response.data) {
    sendNotification(
      <ErrorTitle service={"Success!"} sound={"success"} />,
      "Ok articolo caricato correttamente!",
      "success",
      5
    );
  } else {
    sendNotification(
      <ErrorTitle service={"Attenzione!"} />,
      "Errore articolo non caricato!",
      "warning",
      5
    );
  }
  getInventarioDetail(dispatch)(code);
};

const getListaInventarioHub = (dispatch) => async (code, navigate) => {
  const response = await SpedizioniService.get(
    `${(await useUrlClient()).baseUrl}/inventario/barcode/${code}`
  );
  if (response.data) {
    navigate(`/inventario-hub/${response.data.campichiave.PrgInventario}`);
  }
};

const getInventarioDetailHub = (dispatch) => async (code, navigate) => {
  dispatch({ type: CUSTOM_COLUMN_HUB, payload: [] });
  const response = await SpedizioniService.get(
    `${(await useUrlClient()).baseUrl}/inventario/${code}`
  );
  if (response.data) {
    dispatch({ type: LISTA_INVENTARIO_HUB, payload: response.data.data });
    dispatch({
      type: CAMPI_CHIAVE_HUB,
      payload: {
        campi: response.data.campichiave,
        display: response.data.displaytext,
      },
    });
    dispatch({ type: CUSTOM_COLUMN_HUB, payload: response.data.columns });
  }
};

const postRecordInventarioHub = (dispatch) => async (data, code) => {
  const response = await SpedizioniService.post(
    `${(await useUrlClient()).baseUrl}/inventario/${code}/${data}`
  );
  if (response.data) {
    sendNotification(
      <ErrorTitle service={"Success!"} sound={"success"} />,
      "Ok articolo caricato correttamente!",
      "success",
      5
    );
  } else {
    sendNotification(
      <ErrorTitle service={"Attenzione!"} />,
      "Errore articolo non caricato!",
      "warning",
      5
    );
  }
  getInventarioDetailHub(dispatch)(code);
};

const getSearchInventarioHub = (dispatch) => async (code) => {
  const response = await SpedizioniService.get(
    `${(await useUrlClient()).baseUrl}/inventario/search`
  );
  if (response.data) {
    dispatch({ type: LISTA_HUB_ALL, payload: response.data.data });
    dispatch({ type: CUSTOM_COLUMN_HUB_ALL, payload: response.data.columns });
  }
};

const postNewInventario = (dispatch) => async (form, setDialogOpen, clearForm, reloadcolumns) => {
  const response = await SpedizioniService.post(
    `${(await useUrlClient()).baseUrl}/inventario`,
    form
  );
  if (response.data.esito === "0") {
    sendNotification(
      <ErrorTitle service={"Success!"} sound={"success"} />,
      response.data.msg,
      "success",
      5
    );
    setDialogOpen(false);
    clearForm();
    //navigate("/inventario-hub")
    reloadcolumns();
  } else {
    sendNotification(
      <ErrorTitle service={"Attenzione!"} />,
      response.data.msg,
      "warning",
      5
    );
    //setDialogOpen(true);
  }
  //getInventarioDetailHub(dispatch)(code);
};


export const { Provider, Context } = createDataContext(
  posizioneMerceReducer,
  {
    /* getRefreshListaInventario, */
    getListaInventario,
    postRecordInventario,
    getInventarioDetail,
    getListaInventarioHub,
    postRecordInventarioHub,
    getInventarioDetailHub,
    getSearchInventarioHub,
    postNewInventario
  }, // actions
  INITIAL_STATE // initial state
);
