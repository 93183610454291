import { Stack, TextField } from "@mui/material";
import React, { useEffect } from "react";
import { useForm } from "../hooks";
import { useContext } from "react";
import { MisureColliContext } from "../contexts";

const MagRilevaColliMisureFirstStep = ({ isValidation }) => {
  const {
    state: { spedizione },
    setColli,
    setEpal
  } = useContext(MisureColliContext);
  
  /* const validations = [
    ({ colli }) => isRequired(colli) || { colli: "*Inserisci Colli" },
    ({ epal }) => isRequired(epal) || { epal: "*Inserisci Epal" },
  ]; */

  const {
    values: formSteps,
    changeHandler,
    touched,
    errors,
    isValid,
    setValues,
  } = useForm(
    {
      palette: "",
      colli: "",
      epal: "",
    },
  );

  useEffect(() => {
    setValues({
      palette: spedizione?.palette,
      colli: spedizione?.colli,
      epal: spedizione?.epal,
    });
    setColli(spedizione?.colli)
  }, [spedizione]);

  useEffect(() => {
    isValidation(isValid);
  }, [isValid]);

  const onChangeFieldColli = (e)=>{
    changeHandler("colli", e.target.value)
    setColli(formSteps.colli)
  }

  const onChangeFieldPalette = (e)=>{
    changeHandler("palette", e.target.value)
    setColli(formSteps.palette)
  }

  const onChangeFieldEpal= (e)=>{
    changeHandler("epal", e.target.value)
    setEpal(formSteps.epal)
  }

  return (
    <Stack spacing={2}>
       <TextField
        size="small"
        fullWidth
        autoComplete="colli"
        type="number"
        id="colli"
        label="Palette"
        error={Boolean(touched.palette && errors.palette)}
        helperText={touched.palette && errors.palette}
        variant="outlined"
        value={formSteps.palette || ""}
        onChange={(e) => onChangeFieldPalette(e)}
        onBlur={() => setColli(formSteps.palette)}
      />
      <TextField
        size="small"
        fullWidth
        autoComplete="colli"
        type="number"
        id="colli"
        label="N Colli"
        error={Boolean(touched.colli && errors.colli)}
        helperText={touched.colli && errors.colli}
        variant="outlined"
        value={formSteps.colli || ""}
        onChange={(e) => onChangeFieldColli(e)}
        onBlur={() => setColli(formSteps.colli)}
      />
      <TextField
        size="small"
        fullWidth
        autoComplete="colli"
        type="number"
        id="colli"
        label="N Epal"
        error={Boolean(touched.epal && errors.epal)}
        helperText={touched.epal && errors.epal}
        variant="outlined"
        value={formSteps.epal || ""}
        onChange={(e) => onChangeFieldEpal(e)}
        onBlur={() => setEpal(formSteps.epal)}
      />
    </Stack>
  );
};

export default MagRilevaColliMisureFirstStep;
