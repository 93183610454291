import { Cancel, Done, LoginTwoTone } from "@mui/icons-material";
import {
  Alert,
  Box,
  Grid,
  IconButton,
  InputAdornment,
  Stack,
  TextField,
} from "@mui/material";
import React, { useState } from "react";
import { useContext } from "react";
import { useEffect } from "react";
import { useNavigate, useParams } from "react-router-dom";
import { PosizionamentoContext } from "../contexts";
import { useForm } from "../hooks";
import ReactMarkdown from "react-markdown";
import rehypeRaw from "rehype-raw";
import { TableRowHtml } from "../components";

const colonnaStato = {
  field: "",
  headerName: "Stato ",
  renderCell: (params) => {
    if (params.row.StatoMagMerce !== "OK") {
      return (
        <Stack spacing={2}>
          <IconButton
            color="warning"
            aria-label="upload picture"
            component="span"
          >
            <Cancel />
          </IconButton>
        </Stack>
      );
    } else {
      return (
        <Stack spacing={2}>
          <IconButton
            color="success"
            aria-label="upload picture"
            component="span"
          >
            <Done />
          </IconButton>
        </Stack>
      );
    }
  },
};

const MagPosizionamentoUscitaDettaglio = () => {
  const { idMerce } = useParams();
  let navigate = useNavigate();
  const inputRef = React.useRef();
  const [columns, setColumns] = useState([]);

  useEffect(() => {
    if (idMerce) {
      getBarcodeInfoUscita(idMerce, navigate);
    }
  }, []);

  const {
    state: { magMovim, displayText, customColumn },
    getBarcodeInfoUscita,
    postUscitaMagMovim,
  } = useContext(PosizionamentoContext);

  useEffect(() => {
    let colonne = [];
    if (idMerce && customColumn?.length > 0) {
      customColumn?.forEach((element) => {
        colonne = [
          ...colonne,
          {
            field: element.field,
            headerName: element.headerName,
            width: 250,
          },
        ];
      });
      colonne = [ ...colonne];
      setColumns(colonne);
    }
  }, [customColumn]);

  const validations = [];
  const {
    values: posizioneForm,
    changeHandler,
    setValues,
  } = useForm(
    {
      RiferimPaletta: "",
    },
    validations
  );

  const onkeyEnter = (event) => {
    if (event === 13) {
      postUscitaMagMovim(
        idMerce,
        magMovim[0].PrgMovimento,
        posizioneForm.RiferimPaletta
      );
      setValues({
        RiferimPaletta: "",
      });
      setTimeout(() => {
        inputRef.current.focus();
      }, 100);
    }
  };

  const saveChange = () => {
    postUscitaMagMovim(
      idMerce,
      magMovim[0].PrgMovimento,
      posizioneForm.RiferimPaletta
    );
    setValues({
      RiferimPaletta: "",
    });
    setTimeout(() => {
      inputRef.current.focus();
    }, 100);
  };

  /*   function CustomFooterStatusComponent() {
    return <></>;
  } */

  return (
    <Grid container>
      <Grid container
        sx={{
          position: "fixed",
          background: (theme) => theme.palette.background.paper,
        }}>
        <TextField
          id="outlined-basic"
          label="Riferimento paletta"
          variant="outlined"
          placeholder="Inserire il riferimento paletta "
          fullWidth
          autoFocus
          autoComplete="off"
          inputRef={inputRef}
          onChange={(e) => changeHandler("RiferimPaletta", e.target.value)}
          value={posizioneForm.RiferimPaletta || ""}
          onKeyPress={(e) => onkeyEnter(e.charCode)}
          InputProps={{
            endAdornment: (
              <InputAdornment position="end">
                <IconButton color="primary" onClick={() => saveChange()}>
                  <LoginTwoTone />
                </IconButton>
              </InputAdornment>
            ),
          }}
        />
      </Grid>

      <Grid container mt={8}>
        <Grid item xs={12} md={6} lg={6}>
          <Alert severity="info">
            Movimentazione barcode:{" "}
            {
              <ReactMarkdown
                children={displayText ? displayText : ""}
                rehypePlugins={[rehypeRaw]}
              ></ReactMarkdown>
            }{" "}
          </Alert>
        </Grid>
        <Grid item xs={12} md={12} lg={12}>
          {columns?.length > 0 ? (
              <TableRowHtml customCol={columns} rows={magMovim} />
          ) : (
            <></>
          )}
        </Grid>
      </Grid>
    </Grid>
  );
};

export default MagPosizionamentoUscitaDettaglio;
