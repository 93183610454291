import { getConfig } from '../utilities';


const useUrlClient = async () => {

  let configParmas = await getConfig(localStorage.getItem('path'))

  return configParmas;
}

export default useUrlClient;


