import * as React from "react";
import Webcam from "react-webcam";

import { useState } from "react";
import { Button, Grid, Stack } from "@mui/material";
import { ChangeCircleRounded, PhotoCamera } from "@mui/icons-material";
import { MisureColliContext } from "../contexts";

export default function WebcamCapture({ dimension, closeModalPhoto, cameraSelect, setCameraSelect}) {

  const [image, setImage] = useState("");
  const webcamRef = React.useRef(null);

  const {
    state:{spedizione},
    sendPhoto,
  } = React.useContext(MisureColliContext);
  
  const capture = React.useCallback(() => {
    const imageSrc = webcamRef.current.getScreenshot();
    setImage(imageSrc);
  });

  return (
    <Grid
      container
      spacing={2}
      direction="column"
      alignItems="center"
      justifyContent="center"
    >
      <Grid item xs={12} md={12} lg={12}>
        {image === "" ? (
            <Webcam
              audio={false}
              ref={webcamRef}
              height={400}
              width={450}
              screenshotFormat="image/jpeg"
              forceScreenshotSourceSize
              videoConstraints={{
                width: { ideal: 1280 },
                height: { ideal: 1024 },
                facingMode: { exact: dimension ? cameraSelect? "environment":"user" : cameraSelect? "user":"environment" },
              }}
              /* videoConstraints={{
                width: 800,
                height: 800,
                facingMode: { exact: dimension ? "environment":"user" },
              }} */
            />
        ) : (
          <img
            src={image}
            style={{ width: 320, height: 400 }}
            alt="... attendi"
          />
        )}
      </Grid>

      <Grid item xs={12} md={12} lg={12}>
        {image !== "" ? (
          <Stack direction={"row"} spacing={2}>
            <Button
              variant="contained"
              color="secondary"
              size="small"
              onClick={(e) => {
                e.preventDefault();
                setImage("");
              }}
              className="webcam-btn"
            >
              Ripeti Foto
            </Button>
            <Button
              variant="contained"
              color="primary"
              size="small"
              onClick={(e) => {
                e.preventDefault();
                closeModalPhoto();
                sendPhoto(image, spedizione.campichiave.PrgInterno);                
              }}
              className="webcam-btn"
            >
              Carica Foto
            </Button>
          </Stack>
        ) : (
          <Stack direction={"row"} spacing={2}>
          <Button
            variant="contained"
            color="secondary"
            size="small"
            onClick={(e) => {
              e.preventDefault();
              capture();
            }}
            className="webcam-btn"
            endIcon={<PhotoCamera />}
          >
            Scatta
          </Button>
          <Button
            variant="contained"
            color="primary"
            size="small"
            onClick={(e) => {
              e.preventDefault();
              setCameraSelect(!cameraSelect);
            }}
            className="webcam-btn"
            endIcon={<ChangeCircleRounded />}
          >
            {cameraSelect? "FACE CAM":"BACK CAM"}
          </Button>
          </Stack>
        )}
      </Grid>
    </Grid>
  );
}
