import { Stack, TextField, Grid, Alert, InputAdornment, IconButton } from "@mui/material";
import React, { useRef, useState } from "react";
import { useContext } from "react";
import { useEffect } from "react";
import { useNavigate, useParams } from "react-router-dom";
import { InventarioContext } from "../contexts";
import { useForm } from "../hooks";
import { isRequired } from "../hooks/useForm";
import ReactMarkdown from "react-markdown";
import rehypeRaw from "rehype-raw";
import { Box } from "@mui/system";
import { TableRowHtml } from "../components";
import { LoginTwoTone } from "@mui/icons-material";

const MagInventarioDettaglio = () => {
  const { idInventario } = useParams();
  const inputRef = useRef();
  const inputRef2 = useRef();
  let navigate = useNavigate();
  const [columns, setColumns] = useState([]);

  const {
    state: { listRecord, displayText, customColumn, campiChiave },
    getInventarioDetail,
    postRecordInventario,
  } = useContext(InventarioContext);

  useEffect(() => {
    if (idInventario) {
      getInventarioDetail(idInventario, navigate);
    }
  }, []);

  useEffect(() => {
    let colonne = [];
    if (idInventario && customColumn.length > 0) {
      customColumn.forEach((element) => {
        colonne = [
          ...colonne,
          {
            field: element.field,
            headerName: element.headerName,
            width: 300,
            /*   flex:1, */
          },
        ];
      });
      //colonne = [...colonne, colonnaStato];
      setColumns(colonne);
    }
  }, [customColumn]);

  /*  function CustomFooterStatusComponent() {
    return <></>;
  } */

  const validations = [
    ({ Ubicazione }) =>
      isRequired(Ubicazione) || { Ubicazione: "*Ubicazione obbligatoria" },
  ];

  const {
    values: posizioneForm,
    changeHandler,
    touched,
    errors,
    isValid,
    setValues,
  } = useForm(
    {
      RiferimPaletta: "",
      Ubicazione: "",
    },
    validations
  );

  const keyEnter = (event) => {
    if (event === 13) {
      const timeout = setTimeout(() => {
        inputRef.current.focus();
      }, 100);

      return () => {
        clearTimeout(timeout);
      };
    }
  };

  const onkeyEnter = (event) => {
    if (event === 13) {
      if (posizioneForm.RiferimPaletta && isValid) {
        let data = {
          PrgMovimento: campiChiave.PrgMovimento,
          RiferimPaletta: posizioneForm.RiferimPaletta,
          Ubicazione: posizioneForm.Ubicazione,
        };
        postRecordInventario(data, idInventario);
        //getRefreshListaInventario(idInventario)
        setValues({
          RiferimPaletta: "",
          Ubicazione: "",
        });
        const timeout = setTimeout(() => {
          inputRef2.current.focus();
        }, 100);

        return () => {
          clearTimeout(timeout);
        };
      }
    }
  };

  return (
    <Grid container>
      <Grid
      container
        sx={{
          position: "fixed",
          background: (theme) => theme.palette.background.paper,
        }}
        spacing={1}
      >
        <Grid item xs={12} md={6} lg={6}>
          <TextField
            id="outlined-basic41"
            label="Riferimento paletta"
            variant="outlined"
            placeholder="Inserire il barcode"
            fullWidth
            size="small"
            inputRef={inputRef2}
            autoFocus
            autoComplete={"off"}
            value={posizioneForm.RiferimPaletta || ""}
            onKeyPress={(e) => keyEnter(e.charCode)}
            onChange={(e) => changeHandler("RiferimPaletta", e.target.value)}
          />
        </Grid>
        <Grid item xs={12} md={6} lg={6}>
          <TextField
            id="outlined-basi4c"
            label="Ubicazione"
            variant="outlined"
            size="small"
            placeholder="Inserire ubicazione"
            error={Boolean(touched.Ubicazione && errors.Ubicazione)}
            helperText={touched.Ubicazione && errors.Ubicazione}
            fullWidth
            inputRef={inputRef}
            autoComplete={"off"}
            onKeyPress={(e) => onkeyEnter(e.charCode)}
            value={posizioneForm.Ubicazione || ""}
            onChange={(e) => changeHandler("Ubicazione", e.target.value)}
            InputProps={{
              endAdornment: (
                <InputAdornment position="end">
                  <IconButton
                    color="primary"
                    disabled={!isValid}
                    /* onClick={() => updateUb()} */
                  >
                    <LoginTwoTone />
                  </IconButton>
                </InputAdornment>
              ),
            }}
          />
        </Grid>
      </Grid>

      <Grid container mt={10}>
        <Grid item xs={12} md={6} lg={6}>
          <Alert severity="info" icon={false}>
            {
              <ReactMarkdown
                children={displayText ? displayText : ""}
                rehypePlugins={[rehypeRaw]}
              ></ReactMarkdown>
            }{" "}
          </Alert>
        </Grid>
        <Grid item xs={12} md={12} lg={12}>
          {columns.length > 0 ? (
            <TableRowHtml customCol={columns} rows={listRecord} />
          ) : (
            <></>
          )}
        </Grid>
      </Grid>
    </Grid>
  );
};

export default MagInventarioDettaglio;
