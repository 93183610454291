import { Cancel, Done, LoginTwoTone } from "@mui/icons-material";
import {
  Stack,
  TextField,
  Grid,
  IconButton,
  Alert,
  InputAdornment,
  Box,
} from "@mui/material";
import React, { useRef, useState } from "react";
import { useContext } from "react";
import { useEffect } from "react";
import { useNavigate, useParams } from "react-router-dom";
import { PosizionamentoContext } from "../contexts";
import { useForm } from "../hooks";
import { isRequired } from "../hooks/useForm";
import ReactMarkdown from "react-markdown";
import rehypeRaw from "rehype-raw";
import { TableRowHtml } from "../components";

const colonnaStato = {
  field: "",
  headerName: "Stato ",
  renderCell: (params) => {
    if (params.row.Ubicazione === "") {
      return (
        <Stack spacing={2}>
          <IconButton
            color="warning"
            aria-label="upload picture"
            component="span"
          >
            <Cancel />
          </IconButton>
        </Stack>
      );
    } else {
      return (
        <Stack spacing={2}>
          <IconButton
            color="success"
            aria-label="upload picture"
            component="span"
          >
            <Done />
          </IconButton>
        </Stack>
      );
    }
  },
};

const MagPosizionamentoEntrataDettaglio = () => {
  const { idMerce } = useParams();
  const inputRef = useRef();
  const inputRef2 = useRef();
  let navigate = useNavigate();
  const [columns, setColumns] = useState([]);

  const {
    state: { magMovim, displayText, customColumn },
    getBarcodeInfo,
    postMagMovim,
  } = useContext(PosizionamentoContext);

  useEffect(() => {
    if (idMerce) {
      getBarcodeInfo(idMerce, navigate);
    }
  }, []);

  useEffect(() => {
    let colonne = [];
    if (idMerce && customColumn?.length > 0) {
      customColumn?.forEach((element, i) => {
        colonne = [
          ...colonne,
          {
            field: element.field,
            headerName: element.headerName,
            width: 250,
          },
        ];
      });
      colonne = [colonnaStato, ...colonne];
      setColumns(colonne);
    }
  }, [customColumn]);

  const validations = [
    ({ Ubicazione }) =>
      isRequired(Ubicazione) || { Ubicazione: "*Ubicazione obbligatoria" },
  ];

  const {
    values: posizioneForm,
    changeHandler,
    touched,
    errors,
    isValid,
    setValues,
  } = useForm(
    {
      RiferimPaletta: "",
      Ubicazione: "",
    },
    validations
  );

  const updateUb = () => {
    postMagMovim(posizioneForm, idMerce, magMovim);
    setValues({
      RiferimPaletta: "",
      Ubicazione: "",
    });
  };

  const onkeyEnter = (event) => {
    if (event === 13) {
      if (posizioneForm.RiferimPaletta && isValid) {
        postMagMovim(posizioneForm, idMerce, magMovim);
        setValues({
          RiferimPaletta: "",
          Ubicazione: "",
        });
        const timeout = setTimeout(() => {
          inputRef2.current.focus();
        }, 100);

        return () => {
          clearTimeout(timeout);
        };
      }
    }
  };

  const keyEnter = (event) => {
    if (event === 13) {
      const timeout = setTimeout(() => {
        inputRef.current.focus();
      }, 100);

      return () => {
        clearTimeout(timeout);
      };
    }
  };

  return (
    <Grid container>
      <Grid
        container
        sx={{
          position: "fixed",
          background: (theme) => theme.palette.background.paper,
        }}
        spacing={1}
      >
        <Grid item xs={12} md={6} lg={6}>
          <TextField
            id="outlined-basic41"
            label="Riferimento paletta"
            variant="outlined"
            placeholder="Inserire il barcode"
            fullWidth
            size="small"
            inputRef={inputRef2}
            autoFocus
            autoComplete={"off"}
            value={posizioneForm.RiferimPaletta || ""}
            onKeyPress={(e) => keyEnter(e.charCode)}
            onChange={(e) => changeHandler("RiferimPaletta", e.target.value)}
          />
        </Grid>
        <Grid item xs={12} md={6} lg={6}>
          <TextField
            id="outlined-basi4c"
            label="Ubicazione"
            variant="outlined"
            size="small"
            placeholder="Inserire ubicazione"
            error={Boolean(touched.Ubicazione && errors.Ubicazione)}
            helperText={touched.Ubicazione && errors.Ubicazione}
            fullWidth
            inputRef={inputRef}
            autoComplete={"off"}
            onKeyPress={(e) => onkeyEnter(e.charCode)}
            value={posizioneForm.Ubicazione || ""}
            onChange={(e) => changeHandler("Ubicazione", e.target.value)}
            InputProps={{
              endAdornment: (
                <InputAdornment position="end">
                  <IconButton
                    color="primary"
                    disabled={!isValid}
                    onClick={() => updateUb()}
                  >
                    <LoginTwoTone />
                  </IconButton>
                </InputAdornment>
              ),
            }}
          />
        </Grid>
      </Grid>

      <Grid container mt={10}>
        <Grid item xs={12} md={6} lg={6}>
          <Alert severity="info" icon={false}>
            Movimentazione barcode:{" "}
            {
              <ReactMarkdown
                children={displayText ? displayText : ""}
                rehypePlugins={[rehypeRaw]}
              ></ReactMarkdown>
            }{" "}
          </Alert>
        </Grid>
        <Grid item xs={12} md={12} lg={12}>
          {columns.length > 0 ? (
            <TableRowHtml customCol={columns} rows={magMovim} />
          ) : (
            <></>
          )}
        </Grid>
      </Grid>
    </Grid>
  );
};

export default MagPosizionamentoEntrataDettaglio;
