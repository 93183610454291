import {
    Send,
  } from "@mui/icons-material";
  import {
    Alert,
    Grid,
    IconButton,
    InputAdornment,
    TextField,
  } from "@mui/material";
  
  import React from "react";
  import { useContext } from "react";
  import { useEffect } from "react";
  import { useParams } from "react-router-dom";
  import { CaricoMerceContext } from "../contexts";
  import { useForm } from "../hooks";
  import ReactMarkdown from "react-markdown";
  import rehypeRaw from "rehype-raw";
  import { TableRowHtml } from "../components";
  
  const MagPartenzaTrazioneDettaglio = (props) => {
    const { idColloQualita } = useParams();
     const inputRef = React.useRef();
   
     const {
       state: { columns, viaggio, infoViaggio },
       getControlloColloData,
       postControlloColloData
     } = useContext(CaricoMerceContext);
   
     useEffect(() => {
       if (idColloQualita) {
         getControlloColloData(idColloQualita);
       }
     }, []);
      
     const validations = [];
     const {
       values: posizioneForm,
       changeHandler,
       setValues,
     } = useForm(
       {
         RiferimPaletta: "",
       },
       validations
     );
      
     const onkeyEnter = (event) => {
       if (event === 13) {
         postControlloColloData(
             idColloQualita,
             idColloQualita,
           posizioneForm.RiferimPaletta
         );
         setValues({
           RiferimPaletta: "",
         });
         setTimeout(() => {
           inputRef.current.focus();
         }, 100);
       }
     };
   
     const saveChange = () => {
         postControlloColloData(
         idColloQualita,
         idColloQualita,
         posizioneForm.RiferimPaletta
       );
       setValues({
         RiferimPaletta: "",
       });
       setTimeout(() => {
         inputRef.current.focus();
       }, 100);
     };
      
     return (
      <Grid container>
      <Grid
        container
        sx={{
          position: "fixed",
          background: (theme) => theme.palette.background.paper,
        }}
      >
        <Grid item xs={12} md={6} lg={6}>
          <TextField
            id="outlined-basic"
            label="Segnacollo"
            variant="outlined"
            placeholder="Inserire il segnacollo"
            fullWidth
            autoFocus
            autoComplete="off"
            inputRef={inputRef}
            onChange={(e) => changeHandler("RiferimPaletta", e.target.value)}
            value={posizioneForm.RiferimPaletta || ""}
            onKeyPress={(e) => onkeyEnter(e.charCode)}
            InputProps={{
              endAdornment: (
                <InputAdornment position="end">
                  <IconButton
                    variant="contained"
                    color="secondary"
                    onClick={() => saveChange()}
                  >
                    <Send />
                  </IconButton>
                </InputAdornment>
              ),
            }}
          />
        </Grid>
      </Grid>
      <Grid container mt={8}>
        <Grid item container p={1}>
          <Grid item xs={12} md={6} lg={6}>
            <Alert severity="info" component="span" icon={false}>
              {
                <ReactMarkdown
                  children={
                    infoViaggio?.displaytext
                      ? infoViaggio.displaytext.toString()
                      : " "
                  }
                  rehypePlugins={[rehypeRaw]}
                ></ReactMarkdown>
              }{" "}
            </Alert>
          </Grid>
        </Grid>

        <Grid item xs={12} md={12} lg={12}>
          {columns?.length > 0 ? (
            <TableRowHtml customCol={columns} rows={viaggio} />
          ) : (
            <></>
          )}
        </Grid>
      </Grid>
    </Grid>
    );
  };
  
  export default MagPartenzaTrazioneDettaglio;
  