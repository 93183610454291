import {
  Send,
} from "@mui/icons-material";
import {
  Alert,
  Grid,
  IconButton,
  InputAdornment,
  TextField,
  Dialog,
  Button,
  DialogTitle,
  DialogContentText,
  DialogContent,
  DialogActions,
} from "@mui/material";
import React from "react";
import { useContext } from "react";
import { useEffect } from "react";
import { useNavigate, useParams } from "react-router-dom";
import { CaricoMerceContext } from "../contexts";
import { useForm } from "../hooks";
import ReactMarkdown from "react-markdown";
import rehypeRaw from "rehype-raw";
import { TableRowHtml } from "../components";

const MagPartenzaTrazioneDettaglio = (props) => {
  const navigate = useNavigate()
  const { idBarcodeViaggio } = useParams();
  const [customCol, setCustomCol] = React.useState([]);
  const [dialogControlloSegnacolloOpen, setDialogControlloSegnacolloOpen] = React.useState(false)
  const [dialogEliminazioneSegnacolloOpen, setDialogEliminazioneSegnacolloOpen] = React.useState(false);
  const [tmpRiferimPaletta, setTmpRiferimPaletta] = React.useState("");

  const inputRef = React.useRef();

  const {
    state: { 
            columns,
            viaggio,
            infoViaggio,
            messageDialog,
            //SegnacolloAppartiene,
          },
    getPartenzaPerTrazioneData,
    postPartenzaPerTrazione,
    effettuaEliminazioneSegnacollo,
    postChiusuraPartenzaTrazione
  } = useContext(CaricoMerceContext);


  useEffect(() => {
    if (idBarcodeViaggio) {
      getPartenzaPerTrazioneData(idBarcodeViaggio, navigate);
    }
  }, []);

  const validations = [];
  const {
    values: posizioneForm,
    changeHandler,
    setValues,
  } = useForm(
    {
      RiferimPaletta: "",
    },
    validations
  );



  React.useEffect(() => {
    let colonneDaModificare = [];
    columns.map((res, i) => {
      colonneDaModificare = [
        ...colonneDaModificare,
        {
          field: res.id,
          headerName: res.label,
          minWidth: res.minWidth,
        },
      ];
    });
    setCustomCol(colonneDaModificare);
  }, [columns]);

  const onkeyEnter = (event) => {
    if (event === 13) {
      saveData();
    }
  };

  const saveData = () => {  
    setTmpRiferimPaletta(posizioneForm.RiferimPaletta)  
    postPartenzaPerTrazione(
      idBarcodeViaggio,
      idBarcodeViaggio,
      posizioneForm.RiferimPaletta,
      "",
      setDialogEliminazioneSegnacolloOpen,
      setDialogControlloSegnacolloOpen,
      resetRiferimPaletta
    );

    // setTimeout(() => {
    //   inputRef.current.focus();
    // }, 100);

      resetRiferimPaletta();
    
    handleDialogControlloSegnacolloClose();
  }


  const saveDataConferma = () => {    
    postPartenzaPerTrazione(
      idBarcodeViaggio,
      idBarcodeViaggio,
      tmpRiferimPaletta,
      "true",
      setDialogEliminazioneSegnacolloOpen,
      setDialogControlloSegnacolloOpen ,
      resetRiferimPaletta     
    );

    setValues({
      RiferimPaletta: "",
    });
    // setTimeout(() => {
    //   inputRef.current.focus();
    // }, 100);

    handleDialogControlloSegnacolloClose();
  }


  const resetRiferimPaletta = () => {    
    setValues({
      RiferimPaletta: "",
    });
  }


  const effettuaEliminazione = (barcode) => {
    effettuaEliminazioneSegnacollo(barcode, tmpRiferimPaletta);
    setValues({
      RiferimPaletta: "",
    });
    
    handleDialogEliminazioneSegnacolloClose(false);
  }

  const handleDialogControlloSegnacolloClose = () => {
    setDialogControlloSegnacolloOpen(false);
  }

  const handleDialogEliminazioneSegnacolloClose = () => { 
    setDialogEliminazioneSegnacolloOpen(false);
  }

  return (
    <Grid container>
      <Grid
        container
        sx={{
          position: "fixed",
          background: (theme) => theme.palette.background.paper,
        }}
      >
        <TextField
          id="outlined-basic"
          label="Segnacollo"
          variant="outlined"
          placeholder="Inserire il segnacollo"
          fullWidth
          autoFocus
          autoComplete="off"
          inputRef={inputRef}
          onChange={(e) => changeHandler("RiferimPaletta", e.target.value)}
          value={posizioneForm.RiferimPaletta || ""}
          onKeyPress={(e) => onkeyEnter(e.charCode)}
          InputProps={{
            endAdornment: (
              <InputAdornment position="end">
                <IconButton
                  variant="contained"
                  color="secondary"
                  onClick={() => saveData()}
                >
                  <Send />
                </IconButton>
              </InputAdornment>
            ),
          }}
        />
      </Grid>

      <Grid container mt={8}>
        <Grid item xs={12} md={6} lg={6}>
          <Alert severity="info" component="span">
            {
              <ReactMarkdown
                children={
                  infoViaggio?.displaytext
                    ? infoViaggio.displaytext.toString()
                    : " "
                }
                rehypePlugins={[rehypeRaw]}
              ></ReactMarkdown>
            }{" "}
          </Alert>
          <Alert severity="warning" component="span">
            {
              <ReactMarkdown
                children={
                  infoViaggio?.summarytext
                    ? infoViaggio.summarytext.toString()
                    : " "
                }
                rehypePlugins={[rehypeRaw]}
              ></ReactMarkdown>
            }
          </Alert>
          <Grid item xs={12} md={12} lg={12}>
            {customCol?.length > 0 ? (
              <TableRowHtml customCol={customCol} rows={viaggio} />
            ) : (
              <></>
            )}
          </Grid>

        </Grid>
        
      </Grid>
      <Button
         onClick={() => {postChiusuraPartenzaTrazione(idBarcodeViaggio)}}
         variant="contained"
         sx={{ml:1, mt:1}}
      >
          Chiusura carico
      </Button>

      <Dialog
        open={dialogControlloSegnacolloOpen}
        //consente chiusura toccando o cliccando al di fuori del popup
        onClose={handleDialogControlloSegnacolloClose}        
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
      >
        <DialogTitle id="alert-dialog-title">
          {"Conferma segnacollo non previsto"}
        </DialogTitle>
        <DialogContent>
          <DialogContentText id="alert-dialog-description">
            Il segnacollo non è previsto su questo viaggio.              <br/>
            {/*{SegnacolloAppartiene.InfoMittente !== "" ? (SegnacolloAppartiene.InfoMittente):("")} <br/>
            {SegnacolloAppartiene.InfoDestinatario !== "" ? (SegnacolloAppartiene.InfoDestinatario):("")}<br/>*/}
            Vuoi caricare questo segnacollo sul viaggio?
          </DialogContentText>
        </DialogContent>
        <DialogActions>
          <Button onClick={() => handleDialogControlloSegnacolloClose()}>Non caricare</Button>
          <Button onClick={() => saveDataConferma()} autoFocus>
            Carica
          </Button>
        </DialogActions>
      </Dialog>

            {/* dialogo per conferma eliminazione segnacolli già presenti nel viaggio in seguito alla seconda sparatura del segnacollo. */}
      <Dialog
        open={dialogEliminazioneSegnacolloOpen}
        //consente chiusura toccando o cliccando al di fuori del popup
        onClose={handleDialogEliminazioneSegnacolloClose}        
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
      >
        <DialogTitle id="alert-dialog-title">
          {"Conferma eliminazione segnacollo"}
        </DialogTitle>
        <DialogContent>
          <DialogContentText id="alert-dialog-description">
            {messageDialog.modalmessage}
          </DialogContentText>
        </DialogContent>
        <DialogActions>
          <Button onClick={() => handleDialogEliminazioneSegnacolloClose()}>
            Non confermare
          </Button>
          <Button onClick={() => effettuaEliminazione(idBarcodeViaggio, posizioneForm.RiferimPaletta)} autoFocus>
            Conferma
          </Button>
        </DialogActions>
      </Dialog>
    </Grid>
  );
};

export default MagPartenzaTrazioneDettaglio;
