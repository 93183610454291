import * as React from "react";
import { DataGrid } from "@mui/x-data-grid";
import { Box, Grid, IconButton, LinearProgress, Stack, TextField } from "@mui/material";
import { Edit } from "@mui/icons-material";
import {Link } from "react-router-dom";
import { useContext } from "react";
import { CaricoMerceContext } from "../contexts";
import { MobileDatePicker } from "@mui/x-date-pickers";
import moment from "moment";

function CustomFooterStatusComponent() {
  return <></>;
}

const MagCaricoUscita = () => {
  const [value, setValue] = React.useState(moment().format());
  const [customCol, setCustomCol] = React.useState([]);

  const {
    state: { caricoMerce, columns },
    getCaricoMerce,
  } = useContext(CaricoMerceContext);

  React.useEffect(() => {
    getCaricoMerce("");
  }, []);

  React.useEffect(() => {
    let colonneDaModificare=[];
    columns.map((res, i) => {
      colonneDaModificare = [
        ...colonneDaModificare,
        {
          field: res.id,
          headerName: res.label,
          width:res.minWidth*2,
          sortable: false
        },
      ];
    });
    colonneDaModificare = [
      {
        field: "",
        headerName: "Azioni",
        sortable: false,
        renderCell: (params) => (
          <Stack direction={"row"} spacing={2}>
            <Link to={`/carico-merce-in-uscita/${params.row.PrgViaggio}`}>
              <IconButton
                color="info"
                aria-label="upload picture"
                component="span"
                size="small"
              >
                <Edit />
              </IconButton>
            </Link>
          </Stack>
        ),
      },
      ...colonneDaModificare,
      
    ];
    setCustomCol(colonneDaModificare);
  }, [columns]);

  return (
    <Grid container spacing={2}>
      <Grid item xs={4} md={4} lg={4} m={1}>
        <MobileDatePicker
          label="Date viaggio"
          inputFormat="dd/MM/yyyy"
          value={value}
          onChange={(e) => setValue(moment(e).format())}
          onAccept={() => getCaricoMerce(moment(value).format("yyyy-MM-DD"))}
          renderInput={(params) => <TextField fullWidth {...params} />}
        />
      </Grid>
      <Grid item xs={12} md={12} lg={12}>
        {customCol.length > 0 && columns.length > 0 ? (
          <Box sx={{ width: '100%' }}>
           {/*  <TableRowHtml rows={caricoMerce}
              customCol={customCol}></TableRowHtml> */}
            <DataGrid 
              rows={caricoMerce}
              columns={customCol}
              getRowId={(row) => row.NViaggio}
              disableSelectionOnClick
              disableColumnFilter
              disableVirtualization
              disableColumnSelector
              disableExtendRowFullWidth
              disableDensitySelector
              disableColumnMenu
              autoHeight
              components={{
                Footer: CustomFooterStatusComponent,
              }}
              sx={{ margin: 1 }}
            />
          </Box>
        ) : (
          <LinearProgress p={2} />
        )}
      </Grid>
    </Grid>
  );
};

export default MagCaricoUscita;
