import * as React from "react";
import { DataGrid } from "@mui/x-data-grid";
import {
  Box,
  Button,
  Grid,
  IconButton,
  LinearProgress,
  Stack,
  DialogTitle,
  DialogContentText, 
  DialogContent,
  DialogActions,
  Dialog
} from "@mui/material";
import LocalShippingIcon from '@mui/icons-material/LocalShipping';
import { useContext } from "react";
import { CaricoMerceContext } from "../contexts";
import { ArrowBackOutlined } from "@mui/icons-material";
import { useNavigate, useParams } from "react-router-dom";


function CustomFooterStatusComponent() {
  return <></>;
}

const MagCaricoUscitaCambioViaggio = () => {
  const [customCol, setCustomCol] = React.useState([]);
  const [isDialogOpen, setIsDialogOpen] = React.useState(false);
  const [nuovoViaggio, setNuovoViaggio] = React.useState(0);

  let idSpevia = useParams();
  let navigate = useNavigate();

  const {
    state: { caricoMerce, columns },
    getCaricoMerce,
    postCambioViaggio,
  } = useContext(CaricoMerceContext);

  const handleCambioViaggio = (prgNuovoViaggio) => {
    setIsDialogOpen(true);
    setNuovoViaggio(prgNuovoViaggio);
  };

  const handleConfermaCambio = (prgNuovoViaggio) => {
    postCambioViaggio(prgNuovoViaggio,idSpevia.idsped, idSpevia.idviaggio, navigate);
    setNuovoViaggio(0);
  };

  const handleDialogClose = () => {
    setIsDialogOpen(false);
    setNuovoViaggio(0);
  };


  React.useEffect(() => {
    getCaricoMerce("");
  }, []);

  React.useEffect(() => {
    let colonneDaModificare=[];
    columns.map((res, i) => {
      colonneDaModificare = [
        ...colonneDaModificare,
        {
          field: res.id,
          headerName: res.label,
          width:res.minWidth*2,
          sortable: false
        },
      ];
    });
    colonneDaModificare = [
      {
        field: "",
        headerName: "Azioni",
        sortable: false,
        renderCell: (params) => (
          <Stack direction={"row"} spacing={2}>
              <IconButton
                color="info"
                aria-label="upload picture"
                component="span"
                size="small"
                onClick={() => handleCambioViaggio(params.row.PrgViaggio)}
              >
                <LocalShippingIcon />
              </IconButton>
          </Stack>
        ),
      },
      ...colonneDaModificare,
      
    ];
    setCustomCol(colonneDaModificare);
  }, [columns]);

  return (

    <Grid container spacing={2}>
      <Grid item xs={12} md={12} lg={12}>

        <Button
            startIcon={<ArrowBackOutlined />}
            onClick={() => navigate(`/carico-merce-in-uscita/${idSpevia.idviaggio}`)}
            color="info"
        >
            torna indietro
        </Button>

        {customCol.length > 0 && columns.length > 0 ? (
          <Box sx={{ width: '100%' }}>
            <DataGrid 
              rows={caricoMerce}
              columns={customCol}
              getRowId={(row) => row.NViaggio}
              disableSelectionOnClick
              disableColumnFilter
              disableVirtualization
              disableColumnSelector
              disableExtendRowFullWidth
              disableDensitySelector
              disableColumnMenu
              autoHeight
              components={{
                Footer: CustomFooterStatusComponent,
              }}
              sx={{ margin: 1 }}
            />
          </Box>
        ) : (
          <LinearProgress p={2} />
        )}
      </Grid>

      <Dialog
        open={isDialogOpen}
        onClose={handleDialogClose}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
      >
        <DialogTitle id="alert-dialog-title">
          {"Use Google's location service?"}
        </DialogTitle>
        <DialogContent>
          <DialogContentText id="alert-dialog-description">
            Confermi lo spostamento della spedizione {idSpevia.idspedizione} nel viaggio
          </DialogContentText>
        </DialogContent>
        <DialogActions>
          <Button onClick={handleDialogClose}>Annulla</Button>
          <Button onClick={() => handleConfermaCambio(nuovoViaggio)} autoFocus>
            Conferma
          </Button>
        </DialogActions>
      </Dialog>

    </Grid>
  );
};

export default MagCaricoUscitaCambioViaggio;
