import React from 'react'
import preval      from 'preval.macro';
import moment      from 'moment';
import { Avatar, Chip } from '@mui/material';

const buildTimestamp = preval`module.exports = new Date().getTime();`;


const VersioningApp = () => {
    const getDateString = () => {
        const lastUpdateMoment = moment.unix(buildTimestamp / 1000);
        const formattedDate    = lastUpdateMoment.format('DD.MM.YYYY HH:mm:ss');
        
        return formattedDate;
    };

  return (
    <Chip
    style={{position:"fixed", bottom:"5px", right:"5px", zIndex:'9999'}}
    avatar={<Avatar alt="Natacha" src={require("../static/logo.png")} />}
    label={`MagApp  - ${process.env.REACT_APP_VERSION}  (${getDateString()})`}
    color="info"
  />
  )
}

export default VersioningApp