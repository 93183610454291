import { Send } from "@mui/icons-material";
import {
  Alert,
  Grid,
  IconButton,
  InputAdornment,
  TextField,
  Button,
  Dialog,
  DialogActions,
  DialogTitle,
  DialogContent,
  DialogContentText,
} from "@mui/material";
import React from "react";
import { useContext } from "react";
import { useEffect } from "react";
import { useParams } from "react-router-dom";
import { CaricoMerceContext } from "../contexts";
import { useForm } from "../hooks";
import ReactMarkdown from "react-markdown";
import rehypeRaw from "rehype-raw";
import { TableRowHtml } from "../components";



const MagCaricoViaggioBarcodeDettaglio = (props) => {
  const { idBarcodeViaggio } = useParams();
  const [customCol, setCustomCol] = React.useState([]);
  const [dialogSegnacolloOpen, setDialogSegnacolloOpen] = React.useState(false);
  const inputRef = React.useRef();

  const {
    state: { columns, viaggio, infoViaggio, SegnacolloAppartiene },
    getPartenzaDistribuzioneData,
    postPartenzaDistribuzione,
    postChiusuraPartenzaDistribuzione,
    controlloAppartenenzaSegnacollo,
    clearAppartenenzaSegnacollo
  } = useContext(CaricoMerceContext);

  useEffect(() => {
    if (idBarcodeViaggio) {
      getPartenzaDistribuzioneData(idBarcodeViaggio);
    }
  }, []);

  useEffect(() => {
    if (SegnacolloAppartiene.Previsto === true) {
      saveData()
    }
    else if (SegnacolloAppartiene.Previsto === false) {
      setDialogSegnacolloOpen(true)
    }
  }, [SegnacolloAppartiene]);

  const validations = [];
  const {
    values: posizioneForm,
    changeHandler,
    setValues,
  } = useForm(
    {
      RiferimPaletta: "",
    },
    validations
  );


  React.useEffect(() => {
    let colonneDaModificare = [];
    columns.map((res, i) => {
      colonneDaModificare = [
        ...colonneDaModificare,
        {
          field: res.id,
          headerName: res.label,
          minWidth: res.minWidth,
        },
      ];
    });
    setCustomCol(colonneDaModificare);
  }, [columns]);

  const onkeyEnter = (event) => {
    if (event === 13) {
      controlloAppartenenzaSegnacollo(idBarcodeViaggio, posizioneForm.RiferimPaletta);
    }
  };



  const saveChange = () => {
    controlloAppartenenzaSegnacollo(idBarcodeViaggio, posizioneForm.RiferimPaletta);
  };

  const saveData = () => {
    postPartenzaDistribuzione(
      idBarcodeViaggio,
      idBarcodeViaggio,
      posizioneForm.RiferimPaletta
    );
    setValues({
      RiferimPaletta: "",
    });
    setTimeout(() => {
      inputRef.current.focus();
    }, 100);

    handleDialogSegnacolloClose();
  }

  const handleDialogSegnacolloClose = () => {
    setDialogSegnacolloOpen(false);
    clearAppartenenzaSegnacollo();
  }

  return (
    <Grid container>
      <Grid
        container
        sx={{
          position: "fixed",
          background: (theme) => theme.palette.background.paper,
        }}
      >
        <Grid item xs={12} md={6} lg={6}>
          <TextField
            id="outlined-basic"
            label="Segnacollo"
            variant="outlined"
            placeholder="Inserire il segnacollo"
            fullWidth
            autoFocus
            /* size="small" */
            autoComplete="off"
            inputRef={inputRef}
            onChange={(e) => changeHandler("RiferimPaletta", e.target.value)}
            value={posizioneForm.RiferimPaletta || ""}
            onKeyPress={(e) => onkeyEnter(e.charCode)}
            InputProps={{
              endAdornment: (
                <InputAdornment position="end">
                  <IconButton
                    variant="contained"
                    color="secondary"
                    onClick={() => saveChange()}
                  >
                    <Send />
                  </IconButton>
                </InputAdornment>
              ),
            }}
          />
        </Grid>
      </Grid>
      <Grid container mt={8}>
        <Grid item container p={1}>
          <Grid item xs={12} md={6} lg={6}>
            <Alert severity="info" component="span" icon={false}>
              {
                <ReactMarkdown
                  children={
                    infoViaggio?.displaytext
                      ? infoViaggio.displaytext.toString()
                      : " "
                  }
                  rehypePlugins={[rehypeRaw]}
                ></ReactMarkdown>
              }{" "}
            </Alert>
          </Grid>
          <Grid item xs={12} md={6} lg={6}>
            <Alert severity="warning" component="span" icon={false}>
              {
                <ReactMarkdown
                  children={
                    infoViaggio?.summarytext
                      ? infoViaggio.summarytext.toString()
                      : " "
                  }
                  rehypePlugins={[rehypeRaw]}
                ></ReactMarkdown>
              }
            </Alert>
          </Grid>
        </Grid>

        <Grid item xs={12} md={12} lg={12}>
          {columns?.length > 0 ? (
            <TableRowHtml customCol={customCol} rows={viaggio} />
          ) : (
            <></>
          )}
        </Grid>


      </Grid>

      <Button
         onClick={() => {postChiusuraPartenzaDistribuzione(idBarcodeViaggio)}}
         variant="contained"
         sx={{ml:1, mt:1}}
      >
          Chiusura carico
      </Button>

      <Dialog
        open={dialogSegnacolloOpen}
        //consente chiusura toccando o cliccando al di fuori del popup
        onClose={handleDialogSegnacolloClose}        
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
      >
        <DialogTitle id="alert-dialog-title">
          {"Conferma segnacollo non previsto"}
        </DialogTitle>
        <DialogContent>
          <DialogContentText id="alert-dialog-description">
            Il segnacollo {posizioneForm.RiferimPaletta} non è previsto su questo viaggio.
            Vuoi caricare questo segnacollo sul viaggio?
          </DialogContentText>
        </DialogContent>
        <DialogActions>
          <Button onClick={() => handleDialogSegnacolloClose()}>Non caricare</Button>
          <Button onClick={() => saveData()} autoFocus>
            Carica
          </Button>
        </DialogActions>
      </Dialog>

    </Grid>
  );
};

export default MagCaricoViaggioBarcodeDettaglio;
