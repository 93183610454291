import React from 'react';
import { IconButton, Paper } from '@mui/material';

const IconBox = ({Icon, navigate, route, colorIcon}) => {
    return (
      <Paper
        elevation={3}
        sx={{
          borderRadius: "15px",
          backgroundColor: (theme) => theme.palette[colorIcon].main,
          padding: "10px",
          /* marginTop: "-25px", */
          marginLeft: "15px",
          position: "absolute",
          color: "white",
        }}
      >
        <IconButton aria-label="delete" onClick={()=>navigate(route)}>{Icon}</IconButton>
      </Paper>
    );
};

export default IconBox;