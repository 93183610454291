import * as React from "react";
import Stack from "@mui/material/Stack";
import TextField from "@mui/material/TextField";
import { useNavigate } from "react-router-dom";
import { useForm } from "../hooks";
import { isRequired } from "../hooks/useForm";
import { useContext } from "react";
import { PosizionamentoContext } from "../contexts";
import { IconButton, InputAdornment } from "@mui/material";
import { Search } from "@mui/icons-material";

const MagPosizionamentoEntrata = () => {
  let navigate = useNavigate();
  const inputRef = React.useRef();

  const validations = [
    ({ code }) => isRequired(code) || { code: "*Barcode obbligatorio" },
  ];

  const {
    values: barcodeForm,
    changeHandler,
    touched,
    errors,
    isValid,
  } = useForm(
    {
      code: "",
    },
    validations
  );

  const onkeyEnter = (event) => {
    if (event === 13) {
      if (barcodeForm.code && isValid) {
        getBarcodeInfo(barcodeForm.code, navigate);
      }
    }
  };

  const { getBarcodeInfo } = useContext(PosizionamentoContext);

  React.useEffect(() => {
    setTimeout(() => {
      inputRef.current.focus();
    }, 100);
  }, []);

  return (
    <>
      <Stack direction={"row"} spacing={2} m={2}>
        <TextField
          id="outlined-basic"
          label="Movimento merce in entrata"
          variant="outlined"
          placeholder="Inserire il barcode"
          fullWidth
          autoFocus
          inputRef={inputRef}
          onKeyPress={(e) => onkeyEnter(e.charCode)}
          onChange={(e) => changeHandler("code", e.target.value)}
          error={Boolean(touched.code && errors.code)}
          //helperText={touched.code && errors.code}
          value={barcodeForm.code || ""}
          autoComplete="off"
          InputProps={{
            endAdornment: (
              <InputAdornment position="end">
                <IconButton color="primary" disabled={!isValid}  onClick={() => getBarcodeInfo(barcodeForm.code, navigate)}>
                  <Search />
                </IconButton>
              </InputAdornment>
            ),
          }}
        />
       {/*  <Button
          variant="contained"
          disabled={!isValid}
          startIcon={<LocationOnIcon />}
          onClick={() => getBarcodeInfo(barcodeForm.code, navigate)}
        >
          Cerca
        </Button> */}
        {/* <Button
              variant="contained"
              color="secondary"
              endIcon={<QrCodeScannerIcon />}
              onClick={() => barcodeMode()}
            >
              Scan
            </Button> */}
      </Stack>
    </>
  );
};

export default MagPosizionamentoEntrata;
