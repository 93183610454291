import {  
  Button,
  TextField,
  Dialog,
  DialogContent,
  DialogContentText,
  Grid,
  Typography,
  Box,
  AppBar,
  Toolbar,
  IconButton,
  Stack,
  Slide
} from "@mui/material";
import CloseIcon from '@mui/icons-material/Close';
import { useForm } from "../hooks";
import React from "react";

const Transition = React.forwardRef(function Transition(props, ref) {
  return <Slide direction="up" ref={ref} {...props} />;
});
  
  const CreaViaggioDialog = ({isOpen, setIsOpen, postCreaViaggio, getRicerca}) => {
    const {
        values: CreaViaggioForm,
        changeHandler,
        touched,
        errors, 
        setValues       
      } = useForm(
        {

          AutistaNome :"",
          Targa : "",
          TermArrivo : "",
          TermPartenza : "",
          FornitoreRagSoc: "",
        },    
            
      );

      const clearDialogData = () => {
        setValues({
            ...CreaViaggioForm,
            AutistaNome :"",
            Targa : "",
            TermArrivo : "",
            TermPartenza : "",
            FornitoreRagSoc: "",
          });
      };

      const salvaViaggio = () => {
        postCreaViaggio(CreaViaggioForm, creaViaggioDialogclose);
        getRicerca();        
        
      }

      const creaViaggioDialogclose = () => {
        setIsOpen(false);
        clearDialogData();
      };
      


    return (
      <div>
        <Dialog  
          fullScreen
          open={isOpen}
          onClose={() => creaViaggioDialogclose()}
          TransitionComponent={Transition}  
        >


        {/* <DialogTitle>Creazione viaggio</DialogTitle> */}
        <AppBar sx={{ position: 'relative' }}>
          <Toolbar>
            <IconButton
              edge="start"
              color="inherit"
              onClick={creaViaggioDialogclose}
              aria-label="close"
            >
              <CloseIcon />
            </IconButton>
            <Typography sx={{ ml: 2, flex: 1 }} variant="h6" component="div">
            Creazione viaggio
            </Typography>
            <Button variant="contained" autoFocus color="warning" onClick={creaViaggioDialogclose} sx={{mr:2}}>
              annulla
            </Button>
            <Button variant="contained" autoFocus color="success" onClick={salvaViaggio}>
              crea
            </Button>
          </Toolbar>
        </AppBar>
        <DialogContent>
          
          <DialogContentText >
            <Typography variant="subtitle1">
              <Box sx={{ fontWeight: 'bold', m: 1 }}>
                Inserisci i dati per creare un viaggio.
              </Box>
            </Typography>
          </DialogContentText>

          <Grid container columnSpacing={2} rowspacing={1} sx={{mt:1}}> 
            
            <Grid item xs={12} md={4} lg={6} >
              <TextField 
                margin="normal"
                fullWidth
                size="small"
                label="Autista"
                autoComplete="off"
                value={CreaViaggioForm.AutistaNome || ""}
                helperText={touched.AutistaNome && errors.AutistaNome}
                error={Boolean(touched.AutistaNome && errors.AutistaNome)}
                onChange={(e) => changeHandler("AutistaNome", e.target.value)}
              />
            </Grid>
            <Grid item xs={12} md={4} lg={6}>
              <TextField 
                margin="normal"
                fullWidth
                size="small"
                label="Targa"
                autoComplete="off"
                value={CreaViaggioForm.Targa || ""}
                helperText={touched.Targa && errors.Targa}
                error={Boolean(touched.Targa && errors.Targa)}
                onChange={(e) => changeHandler("Targa", e.target.value)}
              />
            </Grid>

            <Grid item xs={12} md={4} lg={6}>
              <TextField 
                margin="normal"
                fullWidth
                size="small"
                label="Terminal partenza"
                autoComplete="off"
                value={CreaViaggioForm.TermPartenza || ""}
                helperText={touched.TermPartenza && errors.TermPartenza}
                error={Boolean(touched.TermPartenza && errors.TermPartenza)}
                onChange={(e) => changeHandler("TermPartenza", e.target.value)}
              />
            </Grid>

            <Grid item xs={12} md={4} lg={6}>
              <TextField 
                margin="normal"
                fullWidth
                size="small"
                label="Terminal arrivo"
                autoComplete="off"
                value={CreaViaggioForm.TermArrivo || ""}
                helperText={touched.TermArrivo && errors.TermArrivo}
                error={Boolean(touched.TermArrivo && errors.TermArrivo)}
                onChange={(e) => changeHandler("TermArrivo", e.target.value)}
              />
            </Grid>

            <Grid item xs={12} md={12} lg={12}>
              <TextField 
                margin="normal"
                fullWidth
                size="small"
                label="Fornitore"
                autoComplete="off"
                value={CreaViaggioForm.FornitoreRagSoc || ""}
                helperText={touched.FornitoreRagSoc && errors.FornitoreRagSoc}
                error={Boolean(touched.FornitoreRagSoc && errors.FornitoreRagSoc)}
                onChange={(e) => changeHandler("FornitoreRagSoc", e.target.value)}
              />
            </Grid>

          </Grid>
          <Stack direction={"row-reverse"} spacing={2} mt={2}>
            <Button variant="contained" autoFocus color="success" onClick={salvaViaggio}>
              crea
            </Button>
            <Button variant="contained" autoFocus color="warning" onClick={creaViaggioDialogclose} sx={{mr:2}}>
              annulla
            </Button>
          </Stack>
        </DialogContent>

      </Dialog>
      </div>
    )
  }
  
  export default CreaViaggioDialog
