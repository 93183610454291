import { Button, Fab, Stack, TextField } from "@mui/material";
import React from "react";
import SendIcon from "@mui/icons-material/Send";
import { useContext } from "react";
import { MisureColliContext } from "../contexts";
import { useEffect } from "react";
import { useState } from "react";
import { useForm } from "../hooks";
import { useNavigate } from "react-router-dom";
import { Add } from "@mui/icons-material";

const MagRilevaColliMisureSecondStep = ({ showPhoto, note }) => {
  const {
    state: { spedizione, epal },
    postMisureColli,
    sendNote,
  } = useContext(MisureColliContext);

  let navigate = useNavigate();
  const [inseriti, setColliInseriti] = useState([]);
  const validations = [];

  const {
    values: formColli,
    changeHandlers,
    setValues,
  } = useForm([], validations);

  useEffect(() => {
    let array = [];
    for (let i = 0; i < spedizione.spedmerce.length; i++) {
      array = [
        ...array,
        {
          id: i,
          Altezza:
            spedizione.spedmerce.length > 0
              ? spedizione.spedmerce[i]?.Altezza
              : "",
          Colli:
            spedizione.spedmerce.length > 0
              ? spedizione.spedmerce[i]?.Colli
              : 1,
          DescrizioneMerce:
            spedizione.spedmerce.length > 0
              ? spedizione.spedmerce[i]?.DescrizioneMerce
              : "",
          Larghezza:
            spedizione.spedmerce.length > 0
              ? spedizione.spedmerce[i]?.Larghezza
              : "",
          Lunghezza:
            spedizione.spedmerce.length > 0
              ? spedizione.spedmerce[i]?.Lunghezza
              : "",
          Peso:
            spedizione.spedmerce.length > 0
              ? spedizione.spedmerce[i]?.Peso
              : 0.0,
          PrgInterno:
            spedizione.spedmerce.length > 0
              ? spedizione.spedmerce[i]?.PrgInterno
              : 1,
          PrgMerce: i + 1,
        },
      ];
      setColliInseriti(array);
      setValues(array);
    }
  }, []);

  const provaAdd = () => {
    let array = [];
    array = [
      ...formColli,
      {
        id: inseriti.length,
        Altezza: "",
        Colli: "",
        DescrizioneMerce: "",
        Larghezza: "",
        Lunghezza: "",
        Peso: "",
        PrgInterno: "",
        PrgMerce: inseriti.length + 1,
      },
    ];
    setColliInseriti(array);
    setValues(array);
  };

  return (
    <>
      {inseriti.map((res, key) => (
        <React.Fragment key={key}>
          <Stack spacing={1} direction={"row"} mt={2} ml={-2}>
            <TextField
              label="Colli"
              placeholder="quanti"
              id="standard-start-adornment"
              value={
                formColli[key].Colli !== undefined
                  ? formColli[key].Colli
                  : 1 || 1
              }
              variant="standard"
              size="small"
              autoComplete="off"
              type={"number"}
              /* onBlur={()=>onChangeColli()} */
              onChange={(e) => changeHandlers(`Colli`, e.target.value, res.id)}
            />
            <TextField
              label="Lungh"
              id="standard-start-adornment"
              placeholder="cm"
              variant="standard"
              size="small"
              autoComplete="off"
              value={formColli[key].Lunghezza || ""}
              onChange={(e) =>
                changeHandlers(`Lunghezza`, e.target.value, res.id)
              }
              /* type={"number"} */
            />
            <TextField
              label="Largh"
              id="standard-start-adornment"
              placeholder="cm"
              variant="standard"
              size="small"
              autoComplete="off"
              value={formColli[key].Larghezza || ""}
              onChange={(e) =>
                changeHandlers(`Larghezza`, e.target.value, res.id)
              }
              /*type={"number"} */
            />
            <TextField
              label="Altez"
              id="standard-start-adornment"
              placeholder="cm"
              variant="standard"
              size="small"
              autoComplete="off"
              value={formColli[key].Altezza || ""}
              onChange={(e) =>
                changeHandlers(`Altezza`, e.target.value, res.id)
              }
              /*type={"number"}*/
            />
            <TextField
              label="Peso"
              id="standard-start-adornment"
              placeholder="kg"
              variant="standard"
              size="small"
              autoComplete="off"
              value={formColli[key].Peso || ""}
              onChange={(e) => changeHandlers(`Peso`, e.target.value, res.id)}
              /*type={"number"}*/
            />
          </Stack>
        </React.Fragment>
      ))}
      <Stack direction={"row-reverse"} mt={2} spacing={5}>
        <Button
          variant="contained"
          endIcon={<SendIcon />}
          mt={10}
          onClick={() => {
            postMisureColli(
              spedizione.campichiave.PrgInterno,
              formColli,
              navigate,
              epal
            );
            sendNote(note, spedizione.campichiave.PrgInterno);
          }}
        >
          Invia dati
        </Button>
        <Fab
          color="warning"
          aria-label="add"
          size="small"
          onClick={() => provaAdd()}
        >
          <Add />
        </Fab>
      </Stack>
    </>
  );
};

export default MagRilevaColliMisureSecondStep;
