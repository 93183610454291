import * as React from 'react';
import Box from '@mui/material/Box';
import Stepper from '@mui/material/Stepper';
import Step from '@mui/material/Step';
import StepLabel from '@mui/material/StepLabel';
import StepContent from '@mui/material/StepContent';
import Button from '@mui/material/Button';


export default function VerticalLinearStepper({fristStep, secondStep, titleStep, isValid}) {
  const [activeStep, setActiveStep] = React.useState(0);

  const handleNext = () => {
    setActiveStep((prevActiveStep) => prevActiveStep + 1);

  };

  const handleBack = () => {
    setActiveStep((prevActiveStep) => prevActiveStep - 1);
  };

  return (
      <Stepper activeStep={activeStep} orientation="vertical">
        <Step >
            <StepLabel>
            {titleStep.title1}
            </StepLabel>
            <StepContent>
                {fristStep}
              <Box sx={{ mb: 2, direction: 'rtl',}}>
                <div>
                  <Button
                    variant="contained"
                    disabled={!isValid}
                    onClick={handleNext}
                    sx={{ mt: 1, mr: 1 }}
                  >
                  Avanti
                  </Button>
                </div>
              </Box>
            </StepContent>
          </Step>

          <Step >
            <StepLabel>
            {titleStep.title2}
            </StepLabel>
            <StepContent>
            <Box sx={{ mb: 2 , direction: 'rtl',}}>
              <Button
                    onClick={handleBack}
                    variant="contained"
                    color="secondary"
                    sx={{ mt: 1, mr: 1 }}
                  >
                    Indietro
                  </Button>
              </Box>
                {secondStep}
            </StepContent>
          </Step>
      </Stepper>
  );
}