import * as React from "react";
import AppBar from "@mui/material/AppBar";
import CssBaseline from "@mui/material/CssBaseline";
import Toolbar from "@mui/material/Toolbar";

export default function BottomAppBarTwo({child}) {
  return (
    <React.Fragment>
      <CssBaseline />
      <AppBar position="fixed" color="default" sx={{ top: "auto", bottom: 0 }}>
        <Toolbar>
          {child}
        </Toolbar>
      </AppBar>
    </React.Fragment>
  );
}
