import React from "react";
import {
  WebcamCapture,
  VerticalLinearStepper,
  ModalsView,
} from "../components";
import { useWindowDimensions } from "../hooks";
import {
  Alert,
  Button,
  Grid,
  IconButton,
  InputAdornment,
  Stack,
  TextField,
} from "@mui/material";
import { useState } from "react";
import ReactMarkdown from "react-markdown";
import rehypeRaw from "rehype-raw";
import MagRilevaColliMisureFirstStep from "./MagRilevaColliMisureFirstStep";
import MagRilevaColliMisureSecondStep from "./MagRilevaColliMisureSecondStep";
import { useContext } from "react";
import { MisureColliContext } from "../contexts";
import { useEffect } from "react";
import { useParams } from "react-router-dom";
import { PhotoCamera, Send } from "@mui/icons-material";

const MagRilevaColliMisure = () => {
  const [photo, setPhoto] = useState(false);
  const [note, setNote] = useState("");
  const [cameraSelect, setCameraSelect] = useState(true);

  const { code } = useParams();

  const [isValidStep1, setIsValidStep1] = useState(true);

  let dimension = useWindowDimensions();

  const {
    state: { spedizione },
    getRefreshInfoBarcode,
    sendNote,
  } = useContext(MisureColliContext);

  useEffect(() => {
    if (code) {
      getRefreshInfoBarcode(code);
    }
  }, []);

  useEffect(() => {
    if (spedizione) {
      setNote(spedizione?.NotaMagazzino_Spedizione);
    }
  }, [spedizione]);

  const showPhoto = () => {
    setPhoto(!photo);
  };

  let title = {
    title1: "Colli - Epal",
    title2: "Misure",
  };

  const validButton1 = (isValidation) => {
    setIsValidStep1(isValidation);
  };

  const onkeyEnter = (event) => {
    if (event === 13) {
      if (note) {
        sendNote(note, spedizione.campichiave.PrgInterno);
      }
    }
  };
  return (
    <Grid container spacing={2}>
      <Grid item xs={12} md={12} lg={12}>
        <Alert severity="info">
          Movimentazione barcode:{" "}
          {
            <ReactMarkdown
              children={spedizione ? spedizione.displaytext : ""}
              rehypePlugins={[rehypeRaw]}
            ></ReactMarkdown>
          }{" "}
        </Alert>
        <Stack direction={"row-reverse"} mr={2} mb={3} mt={2}>
          <Button
            variant="contained"
            color={"secondary"}
            startIcon={<PhotoCamera />}
            mt={10}
            onClick={() => showPhoto()}
          >
            Foto
          </Button>
        </Stack>
        <Stack direction={"row"} m={1} spacing={2}>
          <TextField
            id="outlined-basic"
            label="Inserisci le note"
            variant="outlined"
            placeholder="Note"
            fullWidth
            autoFocus
            value={note}
            onChange={(e) => setNote(e.target.value)}
            onKeyPress={(e) => onkeyEnter(e.charCode)}
            autoComplete="off"
            InputProps={{
              endAdornment: (
                <InputAdornment position="end">
                  <IconButton
                    color="primary"
                    aria-label="directions"
                    onClick={() => {
                      sendNote(note, spedizione.campichiave.PrgInterno);
                    }}
                  >
                    <Send />
                  </IconButton>
                </InputAdornment>
              ),
            }}
          />
        </Stack>
      </Grid>
      <Grid item xs={12} md={8} lg={8} ml={1}>
        <VerticalLinearStepper
          fristStep={
            <MagRilevaColliMisureFirstStep isValidation={validButton1} />
          }
          secondStep={
            <MagRilevaColliMisureSecondStep
              showPhoto={() => showPhoto()}
              note={note}
            />
          }
          titleStep={title}
          isValid={isValidStep1}
        />
      </Grid>
      <Grid item xs={12} md={12} lg={12} ml={4}>
        <ModalsView
          component={
            <WebcamCapture
              dimension={dimension}
              closeModalPhoto={() => showPhoto()}
              cameraSelect={cameraSelect}
              setCameraSelect={setCameraSelect}
            />
          }
          openModal={photo}
          closeModal={() => showPhoto()}
          title={""}
        />
      </Grid>
    </Grid>
  );
};

export default MagRilevaColliMisure;
