import React, { useContext } from 'react'

import {  
    Box,
    Grid,
    LinearProgress,
    IconButton,
    Stack,
} from "@mui/material";
import { DataGrid } from "@mui/x-data-grid";
import { CaricoMerceContext } from '../contexts';
import { Edit } from "@mui/icons-material";


function CustomFooterStatusComponent() {
    return <></>;
  }

const MagMovimTable = ({columnsData, rowsData, navigate, causale, detailFunc}) => {

    const [customCol, setCustomCol] = React.useState([]);
    
    const onVisualizza = (params, navigate) => {
      detailFunc(params.id, navigate)
    }

    React.useEffect(() => {
        let colonneDaModificare=[];
        if (columnsData? (true):(false)){
        columnsData.map((res, i) => {
          
          colonneDaModificare = [
            ...colonneDaModificare,
            {
              field: res.id,
              headerName: res.label,
              width: res.minWidth < 140? (140):(res.minWidth),
              sortable: false
            },
          ];
        });
        colonneDaModificare = [
           {
             field: "",
             headerName: "Azioni",
             sortable: false,
             renderCell: (params) => (
               <Stack direction={"row"} spacing={2}>
                   <IconButton
                     color="info"
                     aria-label="upload picture"
                     component="span"
                     size="small"
                     onClick={() => onVisualizza(params, navigate)}
                   >
                     <Edit />
                   </IconButton>
               </Stack>
             ),
           },
           ...colonneDaModificare,
          
         ];
        }
        setCustomCol(colonneDaModificare);
      }, [columnsData]);


      const handleRowClick = (params) => {
        detailFunc(params.id, navigate)
      };

  return (
    <Grid container spacing={2}>
        <Grid item xs={12} md={12} lg={12}>
        { columnsData &&  customCol.length > 0 && columnsData.length > 0 ? (
            <Box sx={{ width: '100%' }}>
            {/*  <TableRowHtml rows={caricoMerce}
                customCol={customCol}></TableRowHtml> */}
            <DataGrid 
                onRowClick={handleRowClick}
                rows={rowsData}
                columns={customCol}
                getRowId={(row) => row.PrgMovimento}
                disableSelectionOnClick
                disableColumnFilter
                disableVirtualization
                disableColumnSelector
                disableExtendRowFullWidth
                disableDensitySelector
                disableColumnMenu
                autoHeight
                components={{
                Footer: CustomFooterStatusComponent,
                }}
                sx={{ margin: 1 }}
            />
            </Box>
        ) : (
          <>
            <LinearProgress p={2} />
            
          </>  
        )}
        </Grid>
    </Grid>
  )
}

export default MagMovimTable