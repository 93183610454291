import React from "react";
import { useNavigate } from "react-router-dom";
import { Button, Stack } from "@mui/material";
import { Player } from "@lottiefiles/react-lottie-player";

const NotFound = () => {
  let navigate = useNavigate();

  return (
    <Stack direction={"column"} justifyContent={"center"} spacing={2}>
      <Stack direction={"row"} mt={5} justifyContent={"center"}>
        <Button variant="contained" onClick={() => navigate(-1)}>
          Torna alla Home
        </Button>
      </Stack>
      <Player
        autoplay
        loop
        src="https://assets7.lottiefiles.com/packages/lf20_bhw1ul4g.json"
        style={{ height: "100%", width: "100%" }}
      />
    </Stack>
  );
};

export default NotFound;
