import {
  Stack,
  TextField,
  Grid,
  Alert,
  Button,
  IconButton,
} from "@mui/material";
import React, { useRef, useState } from "react";
import { useContext } from "react";
import { useEffect } from "react";
import { useNavigate, useParams } from "react-router-dom";
import { InventarioContext } from "../contexts";
import { useForm } from "../hooks";
import { isRequired } from "../hooks/useForm";
import ReactMarkdown from "react-markdown";
import rehypeRaw from "rehype-raw";
import { Box } from "@mui/system";
import { TableRowHtml } from "../components";
import { VisibilityOffTwoTone } from "@mui/icons-material";

const colonnaStato = {
  field: "",
  headerName: "Stato ",
  renderCell: (params) => {
    return (
      <IconButton color="warning" aria-label="upload picture" component="span">
        <VisibilityOffTwoTone />
      </IconButton>
    );
  },
};

const MagInventarioHubDettaglio = () => {
  const { idInventarioHub } = useParams();
  const inputRef = useRef();
  let navigate = useNavigate();
  const [columns, setColumns] = useState([]);

  const {
    state: { listRecordHub, displayTextHub, customColumnHub },
    getInventarioDetailHub,
    postRecordInventarioHub,
  } = useContext(InventarioContext);

  useEffect(() => {
    if (idInventarioHub) {
      getInventarioDetailHub(idInventarioHub, navigate);
    }
  }, []);

  useEffect(() => {
    let colonne = [];
    if (idInventarioHub && customColumnHub.length > 0) {
      customColumnHub.forEach((element) => {
        colonne = [
          ...colonne,
          {
            field: element.field,
            headerName: element.headerName,
            width: 300,
            /*   flex:1, */
          },
        ];
      });
      //colonne = [colonnaStato, ...colonne];
      setColumns(colonne);
    }
  }, [customColumnHub]);

  /*  function CustomFooterStatusComponent() {
      return <></>;
    } */

  const validations = [
    ({ barcode }) =>
      isRequired(barcode) || { barcode: "*Ubicazione obbligatoria" },
  ];

  const {
    values: posizioneForm,
    changeHandler,
    touched,
    errors,
    isValid,
    setValues,
  } = useForm(
    {
      barcode: "",
    },
    validations
  );

  const onkeyEnter = (event) => {
    if (event === 13) {
      if (posizioneForm.barcode && isValid) {
        postRecordInventarioHub(posizioneForm.barcode, idInventarioHub);
        //getRefreshListaInventario(idInventario)
        setValues({
          barcode: "",
        });
        const timeout = setTimeout(() => {
          inputRef.current.focus();
        }, 100);

        return () => {
          clearTimeout(timeout);
        };
      }
    }
  };

  return (
    <Grid container>
      <Grid
        container
        sx={{
          position: "fixed",
          background: (theme) => theme.palette.background.paper,
        }}
      >
        <Grid item xs={12} md={12} lg={12}>
          <TextField
            id="outlined-basic"
            label="Barcode"
            variant="outlined"
            placeholder="Inserire barcode"
            error={Boolean(touched.barcode && errors.barcode)}
            helperText={touched.barcode && errors.barcode}
            fullWidth
            inputRef={inputRef}
            autoComplete={"off"}
            onKeyPress={(e) => onkeyEnter(e.charCode)}
            value={posizioneForm.barcode || ""}
            onChange={(e) => changeHandler("barcode", e.target.value)}
          />
        </Grid>
      </Grid>
      <Grid container mt={8} spacing={1}>
        <Grid item xs={12} md={6} lg={6}>
          <Alert severity="info" icon={false}>
            {
              <ReactMarkdown
                children={displayTextHub ? displayTextHub : ""}
                rehypePlugins={[rehypeRaw]}
              ></ReactMarkdown>
            }{" "}
          </Alert>
        </Grid>
        <Grid item xs={12} md={12} lg={12}>
          {columns.length > 0 ? (
            <TableRowHtml customCol={columns} rows={listRecordHub} />
          ) : (
            <></>
          )}
        </Grid>
      </Grid>
    </Grid>
  );
};

export default MagInventarioHubDettaglio;
