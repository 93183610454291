import { Cancel, Done, LoginTwoTone } from "@mui/icons-material";
import {
  Stack,
  TextField,
  Grid,
  IconButton,
  Alert,
  InputAdornment,
  Box,
} from "@mui/material";
import React, { useRef, useState } from "react";
import { useContext } from "react";
import { useEffect } from "react";
import { useNavigate, useParams } from "react-router-dom";
import { PosizionamentoContext } from "../contexts";
import { useForm } from "../hooks";
import { isRequired } from "../hooks/useForm";
import ReactMarkdown from "react-markdown";
import rehypeRaw from "rehype-raw";
import { TableRowHtml } from "../components";

const colonnaStato = {
  field: "",
  headerName: "Stato ",
  renderCell: (params) => {
    if (params.row.Ubicazione === "") {
      return (
        <Stack spacing={2}>
          <IconButton
            color="warning"
            aria-label="upload picture"
            component="span"
          >
            <Cancel />
          </IconButton>
        </Stack>
      );
    } else {
      return (
        <Stack spacing={2}>
          <IconButton
            color="success"
            aria-label="upload picture"
            component="span"
          >
            <Done />
          </IconButton>
        </Stack>
      );
    }
  },
};

const MagAssegnazioneSSCCtoRifPaletta = () => {
  const [columns, setColumns] = useState([]);

  const { idMerce } = useParams();
  const inputRef = useRef();
  const inputRef2 = useRef();
  let navigate = useNavigate();

  const {
    state: { magMovim, displayText, customColumn },
    getBarcodeInfo,
    postAssegnazioneSSCC
  } = useContext(PosizionamentoContext);

  useEffect(() => {
    if (idMerce) {
      getBarcodeInfo(idMerce, navigate);
    }
  }, []);

  useEffect(() => {
    let colonne = [];
    if (idMerce && customColumn?.length > 0) {
      customColumn?.forEach((element, i) => {
        colonne = [
          ...colonne,
          {
            field:element.field,
            headerName:element.headerName,
            width: 250,
          },
        ];
      });
      colonne = [colonnaStato,...colonne];
      setColumns(colonne);
    }
  }, [customColumn]);


  const validations = [
    ({ RiferimSSCC }) =>
      isRequired(RiferimSSCC) || { RiferimSSCC: "*Riferimento SSCC obbligatorio" },
  ];

  const {
    values: posizioneForm,
    changeHandler,
    touched,
    errors,
    isValid,
    setValues,
  } = useForm(
    {
      RiferimPaletta: "",
      RiferimSSCC: "",
    },
    validations
  );

  const updateUb = () => {
    postAssegnazioneSSCC(posizioneForm);
    setValues({
      RiferimPaletta: "",
      RiferimSSCC: "",
    });
  };

  const onkeyEnter = (event) => {
    if (event === 13) {
      if (posizioneForm.RiferimPaletta && isValid) {
        postAssegnazioneSSCC(posizioneForm);
        setValues({
          RiferimPaletta: "",
          RiferimSSCC: "",
        });
        const timeout = setTimeout(() => {
          inputRef2.current.focus();
        }, 100);

        return () => {
          clearTimeout(timeout);
        };
      }
    }
  };

  const keyEnter = (event) => {
    if (event === 13) {
      const timeout = setTimeout(() => {
        inputRef.current.focus();
      }, 100);

      return () => {
        clearTimeout(timeout);
      };
    }
  };

  return (
    <>
      <Grid container spacing={2} sx={{position:"fixed"}}>
        <Grid item xs={12} md={12} lg={12} m={2} >
          {/* <Alert severity="info">
            Movimentazione barcode:{" "}
            {
              <ReactMarkdown
                children={displayText ? displayText : ""}
                rehypePlugins={[rehypeRaw]}
              ></ReactMarkdown>
            }{" "}
          </Alert> */}
          <Stack direction={"column"} spacing={2} mt={2}>
            <TextField
              id="outlined-basic"
              label="Riferimento paletta"
              variant="outlined"
              placeholder="Inserire il riferimento paletta"
              fullWidth
              inputRef={inputRef2}
              autoFocus
              autoComplete={"off"}
              value={posizioneForm.RiferimPaletta || ""}
              onKeyPress={(e) => keyEnter(e.charCode)}
              onChange={(e) => changeHandler("RiferimPaletta", e.target.value)}
            />
            <TextField
              id="outlined-basic"
              label="Riferimento SSCC"
              variant="outlined"
              placeholder="Inserire riferimento SSCC"
              error={Boolean(touched.RiferimSSCC && errors.RiferimSSCC)}
              helperText={touched.RiferimSSCC && errors.RiferimSSCC}
              fullWidth
              inputRef={inputRef}
              autoComplete={"off"}
              onKeyPress={(e) => onkeyEnter(e.charCode)}
              value={posizioneForm.RiferimSSCC || ""}
              onChange={(e) => changeHandler("RiferimSSCC", e.target.value)}
              InputProps={{
                endAdornment: (
                  <InputAdornment position="end">
                    <IconButton
                      color="primary"
                      disabled={!isValid}
                      onClick={() => updateUb()}
                    >
                      <LoginTwoTone />
                    </IconButton>
                  </InputAdornment>
                ),
              }}
            />
          </Stack>
        </Grid>
        {/* <Grid item xs={12} md={12} lg={12} m={1}>
          {columns.length > 0 ? (
            <Box sx={{ width: "100%" }}>

              <TableRowHtml customCol={columns} rows={magMovim} />
            </Box>
          ) : (
            <></>
          )}
        </Grid> */}
      </Grid>
    </>
  );
};

export default MagAssegnazioneSSCCtoRifPaletta;
