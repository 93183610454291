import * as React from "react";
import TextField from "@mui/material/TextField";
import { useNavigate } from "react-router-dom";
import { useForm } from "../hooks";
import { isRequired } from "../hooks/useForm";
import { useContext } from "react";
import { InventarioContext } from "../contexts";
import { 
  Button,
  Grid,
  IconButton,
  InputAdornment,
  ButtonGroup,
  DialogActions,
  DialogContentText,
  DialogContent,
  DialogTitle,
  Dialog,
 } from "@mui/material";
import { 
  Search,
  AddBoxTwoTone
} from "@mui/icons-material";
import { TableRowHtml } from "../components";
import { useState } from "react";
import { useEffect } from "react";

const colonnaStato = {
  field: "action",
  headerName: "Azioni"
};

const MagInventarioHub = () => {
  let navigate = useNavigate();
  const inputRef = React.useRef();
  const [columns, setColumns] = useState([]);
  const [dialogOpen, setDialogOpen] = useState(false);

  const {
    state: { listaHubAll, customColumnHubAll },
    getListaInventarioHub,
    getSearchInventarioHub,
    postNewInventario,
  } = useContext(InventarioContext);

  const validations = [
    ({ code }) => isRequired(code) || { code: "*Barcode obbligatorio" },
  ];

  const {
    values: barcodeForm,
    changeHandler,
    touched,
    errors,
    isValid,
  } = useForm(
    {
      code: "",
      Descrizione:"",
    },
    validations
  );

  const clearForm = () => {
    barcodeForm.code = "";
    barcodeForm.Descrizione = "";
  }

  const onOpenDialog = () => {
    setDialogOpen(true);
  };

  const handleCloseDialogAnulla = () => {
    setDialogOpen(false);
    clearForm()

  };

  const handleCloseDialogConferma = () => {
    postNewInventario(barcodeForm, setDialogOpen, clearForm, getSearchInventarioHub);
  };


  const onkeyEnter = (event) => {
    if (event === 13) {
      if (barcodeForm.code && isValid) {
        getListaInventarioHub(barcodeForm.code, navigate);
      }
    }
  };

  useEffect(() => {
    let colonne = [];
    if (customColumnHubAll.length > 0) {
      customColumnHubAll.forEach((element) => {
        colonne = [
          ...colonne,
          {
            field: element.id,
            headerName: element.label,
            width: 300,
            /*   flex:1, */
          },
        ];
      });
      colonne = [colonnaStato,...colonne];
      setColumns(colonne);
    }
  }, [customColumnHubAll]);

  React.useEffect(() => {
    getSearchInventarioHub();
    setTimeout(() => {
      inputRef.current.focus();
    }, 100);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <div>
      <Grid container>
        <Grid item xs={12} md={12} lg={12} m={2}>
          <TextField
            id="outlined-basic"
            label="Inventario"
            variant="outlined"
            placeholder="Inserire il barcode"
            fullWidth
            autoFocus
            inputRef={inputRef}
            onKeyPress={(e) => onkeyEnter(e.charCode)}
            onChange={(e) => changeHandler("code", e.target.value)}
            error={Boolean(touched.code && errors.code)}
            //helperText={touched.code && errors.code}
            value={barcodeForm.code || ""}
            autoComplete="off"
            InputProps={{
              endAdornment: (
                <InputAdornment position="end">
                  <IconButton
                    color="primary"
                    disabled={!isValid}
                    onClick={() =>
                      getListaInventarioHub(barcodeForm.code, navigate)
                    }
                  >
                    <Search />
                  </IconButton>
                </InputAdornment>
              ),
            }}
          />

          <Grid item xs={12} md={12} lg={12}>
            <ButtonGroup size={"large"} orientation={"horizontal"}>
              <Button 
                onClick={()=> onOpenDialog()} 
                startIcon={<AddBoxTwoTone />} 
                variant="contained"
                sx={{mt:2}}
              >
                CREA INVENTARIO
              </Button>
              </ButtonGroup>
          </Grid>
        </Grid>
        <Grid item xs={12} md={12} lg={12} p={1}>
          {columns.length > 0 ? (
              <TableRowHtml customCol={columns} rows={listaHubAll} routeAction={"/inventario-hub"} fieldAction={"PrgInventario"} />
          ) : (
            <></>
          )}
        </Grid>
      </Grid>
      
      <Dialog open={dialogOpen} onClose={handleCloseDialogAnulla}>
        <DialogTitle>Creazione inventario</DialogTitle>
        <DialogContent>
          <DialogContentText>
            Inserire una descrizione dell'inventario.
          </DialogContentText>
          <TextField
            autoFocus
            margin="dense"
            id="name"
            label="Descrizione"
            type="email"
            fullWidth
            variant="standard"
            onChange={(e) => changeHandler("Descrizione", e.target.value)}
          />
        </DialogContent>
        <DialogActions>
            <Button onClick={handleCloseDialogAnulla} variant="contained" sx={{mr:12 }}>Anulla</Button>
            <Button onClick={handleCloseDialogConferma} variant="contained">Conferma</Button>
        </DialogActions>
      </Dialog>

    </div>
  );
};

export default MagInventarioHub;
