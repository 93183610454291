import React from 'react'

const ErrorTitle = ({ service, sound}) => {

    return <>
      <audio autoPlay src={require(sound ==="success" ? "../positive.wav" : "../negative.mp3")} />
        {service}
    </>
}

export default ErrorTitle