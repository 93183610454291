import { useContext, useEffect, useState } from "react";
import { LanguageContext } from "../contexts";

export const validate = (validations, values) => {
    const errors = validations
        .map(validation => validation(values))
        .filter(validation => typeof validation === 'object');
    return { isValid: errors.length === 0, errors: errors.reduce((errors, error) => ({ ...errors, ...error }), {}) };
};


export const isRequired = (value) => {
    return value !== null && value !== undefined && String(value).trim().length > 0;
};

export const isSame = (value1, value2) => {
    return value1 === value2;
};

export const isNotSame = (value1, value2) => {
    return value1 !== value2;
};

export const isValidLengthPasswod = (password) => {
    if(password.length < 4 ){
        return false;
    }
    return true;
};

export const isEmail = (value) => {
    if(value) {
        // eslint-disable-next-line no-useless-escape
        return /[A-z0-9\.\+_-]+@[A-z0-9\._-]+\.[A-z]{2,6}/.test(value);
    }
    return true;
};


const useForm = (initialState = {}, validations = [], onSubmit = () => { }) => {

    const { state: { languageAbbr } } = useContext(LanguageContext);
    const { isValid: initialIsValid, errors: initialErrors } = validate(validations, initialState);
    const [values, setValues] = useState(initialState);
    const [errors, setErrors] = useState(initialErrors);
    const [isValid, setValid] = useState(initialIsValid);
    const [touched, setTouched] = useState({});

    const changeHandler = (name, value) => {
        const newValues = { ...values, [name]: value };
        const { isValid, errors } = validate(validations, newValues);
        setValues(newValues);
        setValid(isValid);
        setErrors(errors);
        setTouched({ ...touched, [name]: true });
    };

    const changeHandlers = (name, value, idx) => {
        let  newValues = values
        let prova = { ...values[idx], [name]: value }
        let filterValue = [...newValues.filter((res, i)=> i !== idx)]
        newValues = [...filterValue,prova]
        setValues(newValues.sort(function (a, b) {
            return a.id - b.id;
          }));
  };

    useEffect(() => {
        const { isValid, errors } = validate(validations, values);
        setValid(isValid);
        setErrors(errors);
    }, [languageAbbr]);

    const reset = () => {
        const { isValid: initialIsValid, errors: initialErrors } = validate(
            validations,
            initialState
        );
        setTouched({});
        setValues(initialState);
        setErrors(initialErrors);
        setValid(initialIsValid);
    };

    const submitHandler = event => {
        event.preventDefault();
        onSubmit(values);
    };

    return { values, reset, changeHandler, isValid, errors, touched, submitHandler, setValues, changeHandlers };
};

export default useForm;