import React from "react";
import { Html5Qrcode } from "html5-qrcode";

const Qrcode = (props) => {
  const { start, success } = props;
  const { innerWidth: width } = window;

  React.useEffect(() => {
    let html5QrCode;
    if (start) {
      Html5Qrcode.getCameras().then((devices) => {
        if (devices && devices.length) {
          html5QrCode = new Html5Qrcode("qrcode-scanner");
          html5QrCode.start(
            { facingMode: { exact: props.dimension ? "environment" : "user" }},
            {
              fps: 30,
              qrbox: { width: props.dimension ? width/2 : width/3, height: props.dimension ? width/2.2: width/3 },
              disableFlip: true
            },
            success
          );
        }
      });
    }

    return () => {
      if (html5QrCode) {
        html5QrCode.stop();
      }
    };
  }, [start, success]);

  return <div style={{width:"100%"}} id="qrcode-scanner" />;
};

export default Qrcode;