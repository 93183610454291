import { useState, useEffect } from 'react';

/* function getWindowDimensions() {
  const { innerWidth: width, innerHeight: height } = window;
  return {
    width,
    height
  };
} */

function isPhone() {
    const { innerWidth: width } = window;
    if (width > 600){
        return false  
    }
    else {
        return true
    }
  }


export default function useWindowDimensions() {
  const [windowDimensions, setWindowDimensions] = useState(isPhone());

  useEffect(() => {
    function handleResize() {
      setWindowDimensions(isPhone());
    }

    window.addEventListener('resize', handleResize);
    return () => window.removeEventListener('resize', handleResize);
  }, []);


  return windowDimensions;
}